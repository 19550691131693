import React, {Component} from 'react'



class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);


  }


  componentDidMount = () => {

  }

  
  componentDidUpdate = () => {


  }

  


  render() {


    return (
    <div id="changePasswordModal" className="modal modal-fixed-footer">
        <div style={{fontSize: "14pt", textAlign: "center"}} className="modal-content noscrollX">
        
        <div style={{marginBottom: "10px"}}>To change your password, click the button below.</div>
          
          
          <div style={{marginBottom: "20px"}}>You will be logged out.&ensp;A password reset email will be sent to your email address.</div>
        <div>
        <a style={{backgroundColor: "#b93e2b", color: "white", padding: "10px 18px 10px 18px", borderRadius: "5px", fontSize: "11.5pt", boxShadow: "2px 2px 5px rgb(179, 179, 179)"}} onMouseUp={this.props.sendPassReset} onTouchEnd={this.props.sendPassReset} className="modal-close waves-effect waves-red">SEND RESET EMAIL </a>
        </div>

        
        </div>
        <div className="modal-footer">

          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
        </div>
    </div>
    )
  }
}

export default ChangePasswordModal;