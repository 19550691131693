import React, {Component} from 'react'
import { neighborhoods } from '../../cityData/hoods';


class CityModal extends Component {
  constructor(props) {
    super(props);
    
  }

  


  render() {
    var hoods = Object.keys(neighborhoods);
    var modalContent = hoods.map(city => {
      return(
        <p key={city}>
        <label htmlFor={city}>
          <input id={city} type="radio" name="city" className="city" value={city} />
          <span>{city}</span>
        </label>
        </p>
      )
    })


    return(
      <div ref={this.props.modalRef} id="activitiesModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">City</h4>
          {modalContent}

   
        </div>
        <div className="modal-footer">
          <a onMouseUp={this.props.submitCity} onTouchEnd={this.props.submitCity} className="modal-close waves-effect waves-red btn-flat">DONE</a>
        </div>
      </div>
    )
  }
}

export default CityModal;