import React, {Component} from 'react'
// import { activities } from '../../cityData/activities';
import { neighborhoods } from '../../cityData/hoods';
import M from 'materialize-css'
import unfoldArrow from '../../images/unfoldArrow.png'

class HoodsModal extends Component {
  constructor(props) {
    super(props);

    // this.hoodsLength = hoods.length;

  }

  componentDidMount = () => {
    this.modal = document.querySelector('#hoodsModal');
    var modalOptions = {};
    this.hoodsModal = M.Modal.init(this.modal, modalOptions);
    this.props.captureModals(this.hoodsModal);
    this.props.getModalLengths(this.hoodsModal);

  }

  componentDidUpdate = () => {
    this.props.getModalLengths(this.hoodsModal);

  }


  render() {
    var modalContent;
    if (this.props.appState.userData.citySearch === undefined){
      modalContent = <span></span>
    }
    else {
      var cityData;
      if (neighborhoods[this.props.appState.userData.citySearch] === undefined){
        cityData = neighborhoods["New York City / NY Metropolitan Area"];
      } else {
        cityData = neighborhoods[this.props.appState.userData.citySearch];
      }
      var regions = Object.keys(cityData);

      if (this.props.appState.appContainerClass !== "appContainerSmall"){
        modalContent = 
            <div className="row">
              <div className="col l6 m6">
              <label>
                <input 
                onClick={this.props.handleHoodChange} 
                value="all" 
                id="allHoods" 
                type="checkbox" />
                <span style={{fontWeight: "700"}}>ALL</span><br/>
              </label>

              {regions.map(region => {
                  if (regions.indexOf(region) % 2 === 0){
                    var regionNoSpace = region.replace(/ /g,'');
                    return(
                      <div key={region}>
                        <h5 id={regionNoSpace} alt="allow" onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>

                        {cityData[region].map(hood => {
                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                onClick={this.props.handleHoodChange} 
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox" />
                              <span>{hood}</span><br/>
                            </label>
                          )

                        })}
                      </div>
                    )
                  }
              })}

              </div>
              
              <div className="col l6 m6">
                {regions.map(region => {
                    if (regions.indexOf(region) % 2 === 1){
                      var regionNoSpace = region.replace(/ /g,'');
                      return(
                        <div key={region}>
                          <h5 alt="allow" id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>

                          {cityData[region].map(hood => {
                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                onClick={this.props.handleHoodChange} 
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox" />
                              <span>{hood}</span><br/>
                            </label>
                          )
                        })}
                        </div>
                      )
                    }
                })}
              </div>
            </div>
        } else {

          modalContent = 
            <div className="row">
              <div className="col l6 m6">
              <label>
                <input 
                onClick={this.props.handleHoodChange} 
                value="all" 
                id="allHoods" 
                type="checkbox" />
                <span style={{fontWeight: "700"}}>ALL</span><br/>
              </label>

              {regions.map(region => {
                  {/* if (regions.indexOf(region) % 2 === 0){ */}
                    var regionNoSpace = region.replace(/ /g,'');
                    return(
                      <div key={region}>
                        <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>

                        {cityData[region].map(hood => {
                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                onClick={this.props.handleHoodChange} 
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox" />
                              <span>{hood}</span><br/>
                            </label>
                          )

                        })}
                      </div>
                    )
                  {/* } */}
              })}

              </div>
            </div>


        }
    }

    return(
      <div id="hoodsModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">Neighborhoods</h4>
          
          {modalContent}
   
        </div>
        <div className="modal-footer">
          <a onClick={this.props.submitHoods} className="modal-close waves-effect waves-red btn-flat">SEARCH</a>
        </div>
      </div>
    )
  }
}

export default HoodsModal;