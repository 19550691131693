import React, {Component} from 'react'



class PostModal extends Component {
  constructor(props) {
    super(props);



  }

  componentDidMount = () => {
    

  }

  componentDidUpdate = () => {
    // console.log(this.state.postText)

  }


  // componentDidUpdate = () => {
  //   var modals = document.querySelectorAll('.modal');
  //   var modalOptions = {};
  //   this.modals = M.Modal.init(modals, modalOptions);
  // }

  // openMessageModal = () => {
  //   this.modals[0].open();
  //   var messageTextArea = this.messageTextArea.current;
  //   // textArea.defaultValue = "";
  //   messageTextArea.value = ""
  // }

  render() {
    // console.log(this.props)

    return(
      <div>
        <div id="postModal" className="modal modal-fixed-footer">
          <div className="modal-content noscrollX">

            <input maxLength="110" id="postSubject" value={this.props.postSubject} onChange={this.props.handleTyping} placeholder="Subject" type="text" className="browser-default postSubject"></input>

            <textarea maxLength="2000" id="postTextArea" value={this.props.postText} onChange={this.props.handleTyping} placeholder="Post" className="browser-default postTextArea"></textarea>

          </div>
          <div className="modal-footer">
          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
            <span onMouseUp={this.props.sendPost} onTouchEnd={this.props.sendPost} className="modal-close waves-effect waves-red btn indigo darken-3">POST</span>
          </div>
        </div>
      </div>

    )
  }
}

export default PostModal;