import React, {Component} from 'react'
import { Redirect, Link } from 'react-router-dom'
import firebase from "firebase/app";
import { functions } from '../fbConfig'

class EmailFailure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSent: false,
      emailPending: false
    }

    this.sendVerificationEmailVerifyFailure = functions.httpsCallable('sendVerificationEmailVerifyFailure');

  }

  componentDidMount = () => {

    document.title = "Failure | Cool Neighbors";

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

  }

  checkIfAccountExists = () => {
    this.setState({
      emailPending: true
    })

    var email = document.querySelector("#email").value;
    firebase.auth().fetchSignInMethodsForEmail(email)
    .then(result => {
      if (result.length > 0){
        console.log("That account exists.")

        this.sendVerificationEmailVerifyFailure({
          emailAddress: email
        }).then(result => {
          console.log(result);
          alert("Email sent.")
          this.setState({
            emailSent: true
          })
        });

      } else {
        alert("That account does not exist.")
        this.setState({
          emailPending: false
        })
      }
    })

  }


  render() {
    if (this.state.emailSent){
      return <Redirect push to="/" />
    } else {

      return (
        <div style={{fontSize: "14pt", fontWeight: "400", textAlign: "left", marginTop: "10px", maxWidth: "700px"}}>
          Sorry, that link may have expired.<br/><br/>If you're still unable to communicate with other users, you can resend a verification message to your email address by using the form below:<br/><br/>
          
          <input id="email" className="browser-default changeEmailPasswordVerifyFailure" type="email" name="currentEmail" placeholder="Email Address"/><br/><br/>

          <button disabled={this.state.emailPending ? true : false} style={{width: "110px", minWidth: "110px", height: "40px", margin: "0px"}} className="navButton" onMouseUp={this.checkIfAccountExists} onTouchEnd={this.checkIfAccountExists}>RESEND</button>
          
          <br/>
          <br/>To change your email address, login and visit the <Link style={{color: "#b93e2b", fontWeight: "500"}} to="/account">account</Link> page.
        </div>
      )
      }
  }
}

export default EmailFailure;