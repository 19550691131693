import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import ChatEntry from "./ChatEntry";
import { db } from "../../fbConfig";
import { functions } from "../../fbConfig";
import EmailNotConfirmed from "./EmailNotConfirmed";
import Flag from "./Flag";
import M from "materialize-css";

class Correspondence extends Component {
  constructor(props) {
    super(props);

    this.chatTextArea = React.createRef();

    this.correspondenceId = "";
    this.otherUserName = "";
    this.otherUserId = "";
    this.correspondenceData = null;
    this.lastLength = 0;
    this.otherUserData = "";

    this.getServerTimestamp = functions.httpsCallable("getServerTimestamp");
    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );

    this.state = {
      checkingEmail: "",
      otherUserFound: true,
      sendPending: false,
    };
  }

  componentDidMount = () => {
    // var scrollHere = document.querySelector("#scrollHere");
    // scrollHere.scrollIntoView();

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch (e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Correspondence | Cool Neighbors";

    if (this.correspondenceData) {
      this.markAsRead();
    }

    var modals = document.querySelectorAll(".modal");
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);

    if (this.otherUserId && this.otherUserData == "") {
      const userRef = db.collection("userData").doc(this.otherUserId);
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Other user's data found.");
            this.otherUserData = doc.data();
          } else {
            console.log("Other user's data not found.");
            this.setState({
              otherUserFound: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidUpdate = () => {
    // console.log("Chat updated.")
    if (this.correspondenceData) {
      this.markAsRead();
      this.lastLength = Object.keys(this.correspondenceData).length;
    }

    if (this.modals.length === 0) {
      var modals = document.querySelectorAll(".modal");
      var modalOptions = {};
      this.modals = M.Modal.init(modals, modalOptions);
    }

    if (this.otherUserId && this.otherUserData == "") {
      const userRef = db.collection("userData").doc(this.otherUserId);
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Other user's data found.");
            this.otherUserData = doc.data();
          } else {
            console.log("Other user's data not found.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state.checkingEmail !== nextState.checkingEmail) {
      return true;
    } else if (this.state.sendPending !== nextState.sendPending) {
      return true;
    } else {
      var allConversationsWithTimeStamps = nextProps.appState.mailbox;
      var allConversations = {};

      allConversationsWithTimeStamps.forEach((convo) => {
        allConversations[convo[0].correspondence] = convo[0];
      });

      var allConversationKeys = Object.keys(allConversations);
      var correspondenceId = window.location.search.slice(4);

      var currentConvo = {};

      allConversationKeys.forEach((convo) => {
        if (
          correspondenceId ===
          allConversations[convo].correspondence.replace(/ /g, "-")
        ) {
          currentConvo = allConversations[convo];
          this.correspondenceId = allConversations[convo].correspondence;
        }
      });

      var incomingCorrespondenceData = currentConvo.data;
      if (incomingCorrespondenceData) {
        var incomingConvoLength = Object.keys(incomingCorrespondenceData)
          .length;
      }

      if (this.lastLength !== incomingConvoLength) {
        return true;
      } else {
        return false;
      }
    }
  };

  markAsRead = () => {
    var convoData = this.correspondenceData;
    var convoDataKeys = Object.keys(convoData);

    var keysToUpdate = [];
    convoDataKeys.forEach((message) => {
      if (
        convoData[message].receiver === this.props.appState.user.uid &&
        !convoData[message].readByReceiver
      ) {
        keysToUpdate.push(message);
      }
    });
    if (keysToUpdate.length > 0) {
      var convoRef = db.collection("messages").doc(this.correspondenceId);

      var batch = db.batch();

      keysToUpdate.forEach((key) => {
        batch.update(convoRef, {
          [`data.${key}.readByReceiver`]: true,
        });
      });

      batch
        .commit()
        .then(() => {
          console.log("Messages marked as read.");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  sendMessage = () => {
    var lastMessageData = this.correspondenceData[this.lastMessageKey];
    // console.log(this.lastMessageKey)
    // console.log(lastMessageData)

    // console.log(this.correspondenceData["1581123836000-GT8eTtfjI0Z7XdG7BlkmslsupWq2"])
    var extraConvoData = [
      this.correspondenceId,
      lastMessageData.timeStamp,
      lastMessageData.receiver,
    ];
    // console.log(extraConvoData)

    var chatTextArea = this.chatTextArea.current;
    if (this.otherUserData.accountDeleted) {
      alert("This user is no longer available.");
    } else if (chatTextArea.value !== "" && this.otherUserData !== "") {
      this.setState({
        sendPending: true,
      });

      var message = chatTextArea.value;

      var timeStamp;
      // var timeStamp = new Date();
      // timeStamp = Date.parse(timeStamp.toUTCString());

      this.getServerTimestamp().then((result) => {
        timeStamp = result.data;

        var timeStampKey = timeStamp + "-" + this.props.appState.user.uid;

        var messagesRef = db.collection("messages");
        var correspondenceRef = messagesRef.doc(this.correspondenceId);

        correspondenceRef
          .set(
            {
              lastMessageTime: timeStamp,
              data: {
                [timeStampKey]: {
                  message,
                  test: "test",
                  sender: this.props.appState.user.uid,
                  receiver: this.otherUserId,
                  senderName: this.props.appState.userData.firstName,
                  receiverName: this.otherUserName,
                  timeStamp: timeStamp,
                  readBySender: true,
                  readByReceiver: false,
                },
              },
            },
            { merge: true }
          )
          .then(() => {
            console.log("Message sent!");
            chatTextArea.value = "";

            this.setState({
              sendPending: false,
            });

            if (this.otherUserData.notificationTokens !== undefined && this.otherUserData.notificationTokens.length !== 0) {
              console.log("SENDING PUSH!")
              this.props.sendPush({
                name: this.props.appState.userData.firstName,
                tokens: this.otherUserData.notificationTokens,
                message: message
              });
            }

            if (this.otherUserData.receiveNotifications) {
              if (
                extraConvoData[2] === this.otherUserId &&
                timeStamp - extraConvoData[1] > 86400000
              ) {
                console.log(
                  "A notification was sent because I sent the last message and it has been over 24 hours since that last message."
                );
                this.props.sendNotification(
                  this.otherUserId,
                  this.props.appState.userData.firstName,
                  message
                );
              } else if (
                extraConvoData[2] !== this.otherUserId &&
                timeStamp - extraConvoData[1] > 600000
              ) {
                console.log(
                  "A notification was sent because I did not send the last message and it has been 10 min since the other person sent their message."
                );
                this.props.sendNotification(
                  this.otherUserId,
                  this.props.appState.userData.firstName,
                  message
                );
              } else {
                console.log("No notification was sent.");
              }
            }
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            this.setState({
              sendPending: false,
            });
          });
      });
    }
  };

  checkForQuotes = (e) => {
    var userInput = e.target.value;
    var changedValue = userInput.replace(/\ "/g, " “");

    e.target.value = changedValue;
  };

  checkEmailVerified = () => {
    if (this.otherUserData !== "") {
      if (this.props.appState.userData.flagged.includes(this.otherUserId)) {
        alert("You've flagged this user.");
      } else if (
        this.otherUserData.flagged.includes(this.props.appState.userData.uid)
      ) {
        alert("This user has flagged you.");
      } else if (this.props.appState.userData.emailVerified) {
        this.sendMessage();
      } else {
        this.setState(
          {
            checkingEmail: "pending",
          },
          this.confirmEmail
        );
      }
    }
  };

  confirmEmail = () => {
    // console.log(this.state.checkingEmail)
    this.props
      .improvedCheckEmailVerification(this.props.appState.userData.uid)
      .then((emailVerification) => {
        // var emailVerification =
        //   result.data.VerificationAttributes[this.props.appState.user.email]
        //     .VerificationStatus;
        // console.log(emailVerification)

        if (emailVerification === true) {
          console.log("Email verified!");
          var emailVerified = {
            emailVerified: true,
          };
          let usersRef = db
            .collection("userData")
            .doc(this.props.appState.user.uid);
          usersRef.update(emailVerified).then(() => {
            this.sendMessage();
            this.setState({
              checkingEmail: "",
            });
          });
        } else {
          console.log("Email not verified!");
          this.setState({
            checkingEmail: "",
          });
          this.modals[0].open();
        }
      });
  };

  resendVerificationEmail = () => {
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    }).then((result) => {
      console.log("Confirmation email sent.");
      alert("Email sent.");
    });
  };

  openFlagModal = () => {
    if (this.props.appState.userData.flagged.includes(this.otherUserId)) {
      alert("You've already flagged this user.");
    } else if (this.props.appState.userData.emailVerified) {
      this.modals[1].open();
    } else {
      this.setState({
        checkingEmail: "pending",
      });

      this.props
        .improvedCheckEmailVerification(this.props.appState.userData.uid)
        .then((emailVerification) => {
          // var emailVerification =
          //   result.data.VerificationAttributes[this.props.appState.user.email]
          //     .VerificationStatus;

          if (emailVerification === true) {
            console.log("Email verified!");
            var emailVerified = {
              emailVerified: true,
            };
            let usersRef = db
              .collection("userData")
              .doc(this.props.appState.user.uid);
            usersRef.update(emailVerified).then(() => {
              this.setState({
                checkingEmail: "",
              });
              this.modals[1].open();
            });
          } else {
            console.log("Email not verified!");
            this.setState({
              checkingEmail: "",
            });
            this.modals[0].open();
          }
        });
    }
  };

  sendFlag = () => {
    var flagNodes = document.querySelectorAll(".flag");
    var date = new Date();
    var flagInfo = {
      [date]: {
        sexual: flagNodes[0].checked,
        hateful: flagNodes[1].checked,
        selling: flagNodes[2].checked,
        other: flagNodes[3].value,
        flagger: this.props.appState.user.uid,
        flaggee: this.otherUserId,
      },
    };

    var alreadyFlagged = this.props.appState.userData.flagged;
    alreadyFlagged.push(this.otherUserId);

    let flagsRef = db.collection("flags").doc(this.otherUserId);
    flagsRef
      .set(flagInfo, { merge: true })
      .then(() => {
        var flagged = {
          flagged: alreadyFlagged,
        };
        let usersRef = db
          .collection("userData")
          .doc(this.props.appState.user.uid);
        usersRef.update(flagged).then(() => {
          alert("User flagged.");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    // console.log(this.otherUserData);
    // console.log(this.props.appState.userData.firstName)
    var allConversationsWithTimeStamps = this.props.appState.mailbox;
    var allConversations = {};

    allConversationsWithTimeStamps.forEach((convo) => {
      allConversations[convo[0].correspondence] = convo[0];
    });

    // console.log(allConversations);

    var allConversationKeys = Object.keys(allConversations);
    // console.log(allConversationKeys)

    var correspondenceId = window.location.search.slice(4);

    if (this.props.appState.loggedIn === null) {
      return <Redirect to="/" />;
    } else if (correspondenceId === "") {
      return <Redirect to="/" />;
    } else if (allConversationKeys.length < 1) {
      return (
        <span style={{ fontSize: "18pt", fontWeight: "500" }}>
          Loading . . .
        </span>
      );
    } else if (this.state.otherUserFound === false) {
      return <Redirect to="/messages" />;
    } else {
      var currentConvo = {};
      allConversationKeys.forEach((convo) => {
        if (
          correspondenceId ===
          allConversations[convo].correspondence.replace(/ /g, "-")
        ) {
          currentConvo = allConversations[convo];
          this.correspondenceId = allConversations[convo].correspondence;
        }
      });
      var correspondenceData = currentConvo.data;
      var listOfMessageKeys = Object.keys(correspondenceData);
      // var firstMessage = correspondenceData[listOfMessageKeys[0]];
      var lastMessage =
        correspondenceData[listOfMessageKeys[listOfMessageKeys.length - 1]];

      var nameForLabel;
      var otherUserId;
      if (lastMessage.sender === this.props.appState.user.uid) {
        nameForLabel = lastMessage.receiverName;
        this.otherUserName = lastMessage.receiverName;
        otherUserId = lastMessage.receiver;
        this.otherUserId = lastMessage.receiver;
      } else {
        nameForLabel = lastMessage.senderName;
        this.otherUserName = lastMessage.senderName;
        otherUserId = lastMessage.sender;
        this.otherUserId = lastMessage.sender;
      }

      var currentConvoKeys = Object.keys(correspondenceData);
      // console.log(currentConvoKeys)
      var messagesByTimeStamp = [];
      currentConvoKeys.forEach((convo) => {
        var c = correspondenceData[convo];
        messagesByTimeStamp.push([
          c.message,
          c.readByReceiver,
          c.readBySender,
          c.receiver,
          c.receiverName,
          c.sender,
          c.senderName,
          c.test,
          c.timeStamp,
        ]);
      });

      messagesByTimeStamp = messagesByTimeStamp.sort((a, b) => {
        a = a[8];
        b = b[8];
        return b - a;
      });

      // console.log(messagesByTimeStamp)
      // console.log(correspondenceData)
      //FOR FIGURING OUT NOTIFICATIONS
      this.lastMessageKey =
        messagesByTimeStamp[0][8] + "-" + messagesByTimeStamp[0][5];
      // console.log(this.lastMessageKey)

      this.correspondenceData = correspondenceData;

      var messages = messagesByTimeStamp.map((convo) => {
        return (
          <ChatEntry
            uid={this.props.appState.user.uid}
            key={convo[8]}
            convoData={convo}
          />
        );
      });

      var buttons;
      // console.log(this.state.checkingEmail)
      if (this.state.checkingEmail === "pending") {
        // console.log("test")
        buttons = (
          <div>
            <button disabled className="chatSend">
              Send
            </button>
            <br />
            <button disabled className="chatFlag">
              Flag
            </button>
          </div>
        );
      } else if (this.state.sendPending) {
        buttons = (
          <div>
            <button disabled className="chatSend">Send</button>
            <br />
            <button disabled className="chatFlag">Flag</button>
          </div>
        );
      } else {
        buttons = (
          <div>
            <button
              onMouseUp={this.checkEmailVerified}
              onTouchEnd={this.checkEmailVerified}
              className="chatSend"
            >
              Send
            </button>
            <br />
            <button
              onMouseUp={this.openFlagModal}
              onTouchEnd={this.openFlagModal}
              className="chatFlag"
            >
              Flag
            </button>
          </div>
        );
      }

      console.log(this.state.sendPending);

      return (
        <div>
          <div className="messagesWithHeading">
            Messages with{" "}
            <Link style={{ color: "#b93e2b" }} to={`/${otherUserId}`}>
              {nameForLabel}
            </Link>
          </div>
          <br />
          <div className="chatSendContainer">
            <div className="chatTextContainer">
              <textarea
                maxLength="2000"
                onChange={this.checkForQuotes}
                ref={this.chatTextArea}
                className="chatTextArea"
              ></textarea>
            </div>

            <div className="chatButtonContainer">{buttons}</div>
          </div>
          {messages}
          <br />
          <br />

          <EmailNotConfirmed
            resendVerificationEmail={this.resendVerificationEmail}
          />

          <Flag sendFlag={this.sendFlag} />
        </div>
      );
    }
  }
}

export default Correspondence;
