import React, { Component } from 'react'
import edit from "../../images/editIcon.png"
import check from "../../images/check.svg"



class Name extends Component {
  constructor(props) {
    super(props);

  }

  render(){
    return (
      <div>

        <div>
          {/* FIRST NAME EDIT ICON */}
            <img onMouseUp={this.props.toggleEditName} onTouchEnd={this.props.toggleEditName}
            src={edit} 
            className={`
              editIcon
              editIconName
              ${this.props.editingName ? "hide" : ""}
              ${this.props.appState.appContainerClass === "appContainerSmall" ? "iconNameSmall" : ""}
          `} />
        

        {/* FIRST NAME */}
        <div className={`
          heading3 
          ${this.props.editingName ? "hide" : ""}
          ${this.props.appState.appContainerClass === "appContainerSmall" ? "smallerProfileHeading" : ""}
        `}
        >{this.props.userData.firstName}</div>
        </div>

      
        {/* EDIT FIRST NAME INPUT */}
        <div className={this.props.editingName ? "" : "hide"}>
        <input className={`
          browser-default 
          editNameInput
        `} 
        type="text" 
        defaultValue = {this.props.userData.firstName} 
        placeholder="First Name" 
        maxLength="26"/>
        
        <img className="checkIcon" src={check} onMouseUp={this.props.submitName} onTouchEnd={this.props.submitName} />
        </div>

      </div>
      )
    }

}


export default Name;