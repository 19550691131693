import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
// import { auth } from '../../fbConfig'
// import { db } from '../../fbConfig'
import MessageRow from "./MessageRow"

class Messages extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Messages | Cool Neighbors";

  }

  render() {
    var mailbox = this.props.appState.mailbox;

    if (this.props.appState.loggedIn === null){
      return <Redirect to="/signin" />
    }
    if (Object.keys(mailbox).length < 1){
      return <div className="messagesHeading">Messages</div>
    }
    else {
      //console.log(mailbox); // This is an correspondence array of objects.  Need to know which one comes first.

      // Need to know highest timestamp in the data (messages) sub-object of the above mailbox array of objects

      var mailboxContent = mailbox.map(correspondence => {

        var unreadMessages = false;
        if (correspondence[2] === this.props.appState.userData.uid && correspondence[3] === false){
          // console.log(correspondence[0])
          // console.log("New messages!")
          var unreadMessages = true;
        }

        correspondence = correspondence[0];
        return (
          <MessageRow unreadMessages={unreadMessages} appState={this.props.appState} currentUserId={this.props.appState.userData.uid} messageInfo={correspondence} key={correspondence.correspondence}/>
        )
        
      });

      
      return(
      <div>
        <div className="messagesHeading">Messages</div>
        {mailboxContent}
        <br/><br/><br/>
      </div>
      )
    }
  }
}

export default Messages;