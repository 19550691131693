import React, {Component} from 'react'


class Flag extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
    
  }

  checkQuotes = (e) => {
    e.target.value = e.target.value.replace(/\ "/g, " “")
  }

  render() {
    return(
      <div id="flagModal" className="modal modal-fixed-footer flagModal">
        <div id="flagModalContent" className="modal-content noscrollX">

            <h4 style={{marginBottom: "6px"}} className="modalHeading">Flag User</h4>
            <p style={{lineHeight: "30px", marginBottom: "6px", marginTop: "6px"}}>

            <label>
              <input className="flag" id="flag1" type="checkbox" value="sexual"/>
              <span style={{fontSize: "12pt"}}>This user has sent or posted material that is date-seeking or sexual in nature.</span>
            </label><br/>

            <label>
              <input className="flag" id="flag2" type="checkbox" value="hateful"/>
              <span style={{fontSize: "12pt"}}>This user has sent or posted words that are hateful or extremely disrespectful.</span>
            </label><br/>

            <label>
              <input className="flag" id="flag3" type="checkbox" value="selling"/>
              <span style={{fontSize: "12pt"}}>This user is selling something.</span>
            </label><br/>
            
            </p>
          <span style={{fontSize: "11.5pt"}}>Additional info:</span>

          <textarea maxLength="2000" onChange={this.checkQuotes} className="flag flagTextArea" id="flag4"></textarea>

          <div style={{fontSize: "10.5pt"}}>

            <span style={{fontWeight: "700"}}>Note:</span>&ensp;Flagging a user also blocks that user from communicating with you.

          </div>

        </div>
        <div className="modal-footer">

          <a href="#!" className="modal-close btn-flat">Cancel</a>
          <a style={{fontWeight: "700"}} id="sendFlagButton" href="#!" onMouseUp={this.props.sendFlag} onTouchEnd={this.props.sendFlag} className="modal-close btn-flat">Flag</a>

        </div>
      </div>
    )
  }
}

export default Flag;