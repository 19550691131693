import React, {Component} from 'react'
import { activities } from '../../cityData/activities';
import M from 'materialize-css'

class ActivitiesModal extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
    this.modal = document.querySelector('#activitiesModal');
    var modalOptions = {
      preventScrolling: false
    };
    this.activitiesModal = M.Modal.init(this.modal, modalOptions);
    this.props.captureModals(this.activitiesModal);
    this.props.getModalLengths(this.activitiesModal);
  }

  componentDidUpdate = () => {
    this.props.getModalLengths(this.activitiesModal);
  }


  render() {
    var halfwayPoint = Math.floor(activities.length/2) - 1
    var modalContent = 
        <div className="row">
          <div className="col l6 m6">
          <label>
            <input 
            onClick={this.props.handleActivityChange} 
            value="all" 
            id="allActivities" 
            type="checkbox" />
            <span style={{fontWeight: "700"}}>ALL</span><br/>
          </label>
            
            {activities.map(activity => {
              if (activities.indexOf(activity) <= halfwayPoint){
              return(
              <label key={activity}>
                <input
                onClick={this.props.handleActivityChange} 
                value={activity} 
                id={activity} 
                className="activity" 
                type="checkbox" />
                <span>{activity}</span><br/>
              </label>
              )
            }
            })}
          </div>
          <div className="col l6 m6">{activities.map(activity => {
            if (activities.indexOf(activity) > halfwayPoint){
              return(
              <label key={activity}>
                <input
                onClick={this.props.handleActivityChange} 
                value={activity} 
                id={activity} 
                className="activity" 
                type="checkbox" />
                <span>{activity}</span><br/>
              </label>
              )
            }
            })}
          </div>
        </div>

    return(
      <div id="activitiesModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">Activities</h4>
          
          {modalContent}
   
        </div>
        <div className="modal-footer">
          <a onClick={this.props.submitActivities} className="modal-close waves-effect waves-red btn-flat">SEARCH</a>
        </div>
      </div>
    )
  }
}

export default ActivitiesModal;