import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { auth } from "../../fbConfig";
import { db } from "../../fbConfig";
import ChangeEmailModal from "./ChangeEmailModal.js";
import ChangePasswordModal from "./ChangePasswordModal.js";
import DeleteAccount from "./DeleteAccount.js";
import M from "materialize-css";
import firebase from "firebase/app";
import { functions } from "../../fbConfig";

class Account extends Component {
  constructor(props) {
    super(props);

    var displayedEmail = "";
    if (!this.props.appState.loggedIn) {
      displayedEmail = "";
    } else {
      displayedEmail = this.props.appState.user.email;
    }

    this.state = {
      currentEmail: "",
      currentPassword: "",
      newEmail: "",
      displayedEmail: displayedEmail,
      changeEmailPending: false,
      changePasswordPending: false,
      deleteEmail: "",
      deletePassword: "",
    };

    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // test = () => {
  //   console.log(this.state.deleteEmail)
  // }

  componentDidMount = () => {
    document.title = "Account | Cool Neighbors";
    console.log(this.props.appState.user)
    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch (e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    var modals = document.querySelectorAll(".modal");
    var modalOptions = {
      onCloseEnd: null,
    };
    this.modals = M.Modal.init(modals, modalOptions);
  };

  componentDidUpdate = () => {
    if (this.modals.length === 0) {
      var modals = document.querySelectorAll(".modal");
      if (this.modals.length < 1) {
        var modalOptions = {
          onCloseEnd: null,
        };
        this.modals = M.Modal.init(modals, modalOptions);
      }
    }
  };

  changeEmail = () => {
    if (this.state.newEmail !== "") {
      var user = auth.currentUser;

      var newEmail = this.state.newEmail;
      var credentials = firebase.auth.EmailAuthProvider.credential(
        this.state.currentEmail,
        this.state.currentPassword
      );

      this.setState({
        changeEmailPending: true,
      });

      user.reauthenticateWithCredential(credentials).then(() => {
        console.log("User re-authenticated.");

        user
          .updateEmail(this.state.newEmail)
          .then(() => {
            console.log("Email updated.");
            var emailVerifyCode = Math.floor(Math.random() * 1000000);

            var newEmailCode = {
              confirmed: false,
              code: emailVerifyCode,
            };

            db.collection("confirmedEmails")
              .doc(this.props.appState.userData.uid)
              .set(newEmailCode, { merge: true })
              .then(() => {
                console.log("New email added to confirm list.");
                this.improvedSendVerificationEmail({
                  emailAddress: newEmail,
                  emailVerifyCode: emailVerifyCode,
                  firstName: this.props.appState.userData.firstName,
                  uid: this.props.appState.userData.uid,
                })
                  .then(() => {
                    console.log("Verification email sent.");

                    var emailVerified = {
                      emailVerified: false,
                      emailVerifyCode: emailVerifyCode,
                    };
                    let usersRef = db
                      .collection("userData")
                      .doc(this.props.appState.user.uid);
                    usersRef.update(emailVerified).then(() => {
                      console.log("Email verified boolean set to false.");

                      alert(
                        "Updated! Check your inbox to confirm your new address."
                      );

                      this.setState({
                        currentEmail: "",
                        currentPassword: "",
                        newEmail: "",
                        displayedEmail: newEmail,
                        changeEmailPending: false,
                      });
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                    alert(error);
                    this.setState({
                      changeEmailPending: false,
                    });
                  });
              })
              .catch((error) => {
                console.log(error);
                alert(error);
                this.setState({
                  changeEmailPending: false,
                });
              });
          })
          .catch((error) => {
            console.log(error);
            alert(error);
            this.setState({
              changeEmailPending: false,
            });
          });
      }).catch((error) => {
        console.log(error);
        alert(error);
        this.setState({
          changeEmailPending: false,
        });
      });;
    }
  };

  reauthenticateForDelete = () => {
    var user = auth.currentUser;

    var credentials = firebase.auth.EmailAuthProvider.credential(
      this.state.deleteEmail,
      this.state.deletePassword
    );

    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        console.log("User re-authenticated.");
        this.props.appState.deleteAccount();
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  sendPassReset = () => {
    this.setState({
      changePasswordPending: true,
    });

    var auth = firebase.auth();

    auth
      .sendPasswordResetEmail(this.props.appState.user.email)
      .then(() => {
        this.setState({
          changePasswordPending: false,
        });

        alert("Email sent.  You will now be logged out.");
        this.props.appState.connectOnOff();
        this.props.appState.connectMailboxOneOnOff();
        this.props.appState.connectMailboxTwoOnOff();
        auth.signOut().then(() => {
          console.log("Logged out.");
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          changePasswordPending: false,
        });
      });
  };

  openEmailModal = () => {
    this.modals[0].open();
  };

  openPassModal = () => {
    this.modals[1].open();
  };

  openDeleteModal = () => {
    this.modals[2].open();
  };

  switchChange = (e) => {
    // console.log(e.target.checked)

    var notificationSetting = {
      receiveNotifications: e.target.checked,
    };
    let usersRef = db.collection("userData").doc(this.props.appState.user.uid);
    usersRef.update(notificationSetting);
  };

  render() {
    // console.log(userData)
    if (this.props.appState.loggedIn === null) {
      return <Redirect to="/signin" />;
    } else if (this.props.appState.userData.uid === undefined) {
      return <div></div>;
    } else {
      var userData = this.props.appState.userData;
      var changeEmailButton;
      var changePasswordButton;

      if (this.state.changeEmailPending) {
        changeEmailButton = (
          <button disabled className="accountButton">
            Please wait...
          </button>
        );
      } else {
        changeEmailButton = (
          <button
            onMouseUp={this.openEmailModal}
            onTouchEnd={this.openEmailModal}
            className="accountButton"
          >
            Change Email Address
          </button>
        );
      }

      if (this.state.changePasswordPending) {
        changePasswordButton = (
          <button disabled className="accountButton">
            Please wait...
          </button>
        );
      } else {
        changePasswordButton = (
          <button
            onMouseUp={this.openPassModal}
            onTouchEnd={this.openPassModal}
            className="accountButton"
          >
            Change Password
          </button>
        );
      }

      return (
        <div>
          <div className="accountHeading">Account</div>
          <div
            style={{ fontSize: "14pt", fontWeight: "400", marginLeft: "14px" }}
          >
            <div>You are currently logged in as:</div>

            <div
              style={{
                fontWeight: "600",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {this.state.displayedEmail}
            </div>

            {changeEmailButton}
            <br />

            {changePasswordButton}
            <br />

            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "inline-block",
              }}
            >
              Email Notifications:&emsp;
            </div>

            <div
              style={{ display: "inline-block", marginBottom: "50px" }}
              className="switch"
            >
              <label style={{ fontSize: "12pt", color: "#181818" }}>
                Off
                <input
                  checked={userData.receiveNotifications ? true : false}
                  onChange={this.switchChange}
                  type="checkbox"
                />
                <span className="lever"></span>
                On
              </label>
            </div>
            <br />

            <button
              onMouseUp={this.openDeleteModal}
              onTouchEnd={this.openDeleteModal}
              className="accountButton"
            >
              Delete Account
            </button>
            <br />
            <br />
            <br />

            <ChangeEmailModal
              accountPageState={this.state}
              handleChange={this.handleChange}
              changeEmail={this.changeEmail}
            />

            <ChangePasswordModal
              sendPassReset={this.sendPassReset}
              appState={this.props.appState}
            />

            <DeleteAccount
              reauthenticateForDelete={this.reauthenticateForDelete}
              handleChange={this.handleChange}
              accountPageState={this.state}
              appState={this.props.appState}
            />
          </div>
        </div>
      );
    }
  }
}

export default Account;
