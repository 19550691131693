import React, {Component} from 'react'



class DeleteAccount extends Component {
  constructor(props) {
    super(props);

    

  }


  componentDidMount = () => {
    // console.log(this.props.appState)

  }


  componentDidUpdate = () => {

    // console.log(this.props.appState)
  }


  render() {
    return (
    <div id="changeEmailModal" className="modal modal-fixed-footer">
        <div style={{fontSize: "14pt", textAlign: "center"}} className="modal-content noscrollX">
        
        <div style={{marginBottom: "10px"}}>Type your <span style={{fontWeight: "700"}}>current</span> email address and password below.</div>

        <input value={this.props.accountPageState.deleteEmail} onChange={this.props.handleChange} className="browser-default changeEmailPassword" type="email" name="deleteEmail" placeholder="Current Email"/><br/>

        <input value={this.props.accountPageState.deletePassword} onChange={this.props.handleChange} className="browser-default changeEmailPassword" type="password" name="deletePassword" placeholder="Password"/>

        <div style={{textAlign: "center"}}>
          <a style={{marginTop: "26px", backgroundColor: "#b93e2b", color: "white", padding: "10px 18px 10px 18px", borderRadius: "5px", fontSize: "11.5pt", boxShadow: "2px 2px 5px rgb(179, 179, 179)"}} onMouseUp={this.props.reauthenticateForDelete} onTouchEnd={this.props.reauthenticateForDelete} className="waves-effect waves-red">DELETE ACCOUNT </a>
        </div>

        </div>
        <div className="modal-footer">
        
          <a onMouseUp={this.props.changeEmail} onTouchEnd={this.props.changeEmail} className="modal-close waves-effect waves-red btn-flat">CANCEL</a>
        </div>
    </div>
    )
  }
}

export default DeleteAccount;