import React, { Component } from "react";
import M from "materialize-css";
import { auth } from "../../fbConfig";
import { functions } from "../../fbConfig";
import { db } from "../../fbConfig";

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.messageTextArea = React.createRef();


    this.state = {
      verifyingEmailAddress: "",
    };

    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
  }

  // modalClosed = () => {
  //   console.log("closed")
  // }

  componentDidMount = () => {
    var messageModals = document.querySelectorAll(".messageModal");
    var modalOptions = {
      onCloseEnd: () => this.setState({ verifyingEmailAddress: "" }),
    };
    this.modals = M.Modal.init(messageModals, modalOptions);

    if (this.props.appState.userData.emailVerified) {
      this.setState({
        verifyingEmailAddress: "complete",
      });
    }
  };

  // shouldComponentUpdate = (nextProps, nextState) => {
  // if (nextProps.messageText !== this.messageTextArea.value){
  //   return false
  // } else {
  //   return true
  // }
  // }

  resendVerificationEmail = () => {
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    }).then((result) => {
      console.log("Confirmation email sent.");
      alert("Email sent.");
    });
  };

  componentDidUpdate = () => {
    // console.log(this.props.appState.userData.emailVerified)

    if (this.modals.length === 0) {
      var messageModals = document.querySelectorAll(".messageModal");
      var modalOptions = {
        onCloseEnd: () => this.setState({ verifyingEmailAddress: "" }),
      };
      this.modals = M.Modal.init(messageModals, modalOptions);
    }
  };

  openMessageModal = (e) => {
    if (this.props.appState.userData.flagged.includes(this.props.otherUserId)) {
      alert("You've flagged this user.");
    } else if (
      this.props.otherUsersFlags.includes(this.props.appState.userData.uid)
    ) {
      alert("This user has flagged you.");
    } else if (this.props.appState.userData.emailVerified) {
      this.modals[0].open();
      // var messageTextArea = this.messageTextArea.current;
      // messageTextArea.value = ""
      // alert(e.type)
    } else {
      this.setState({
        verifyingEmailAddress: "pending",
      });

      this.props
        .improvedCheckEmailVerification(this.props.appState.userData.uid)
        .then((emailVerification) => {
          // var emailVerification =
          //   result.data.VerificationAttributes[this.props.appState.user.email]
          //     .VerificationStatus;

          if (emailVerification === true) {
            console.log("Email verified!");
            var emailVerified = {
              emailVerified: true,
            };
            let usersRef = db
              .collection("userData")
              .doc(this.props.appState.user.uid);
            usersRef.update(emailVerified).then(() => {
              this.modals[0].open();
              var messageTextArea = this.messageTextArea.current;
              messageTextArea.value = "";
            });
          } else {
            console.log("Email not verified!");
            this.modals[0].open();
          }
        });
    }
  };

  render() {
    // console.log(this.props);
    var button;
    if (this.props.currentUserId === undefined) {
      button = (
        <button disabled className="navButton sendMessageButton">
          Send Message
        </button>
      );
    } else if (this.props.otherUserId === this.props.currentUserId) {
      button = (
        <button className="navButton sendMessageButton">Send Message</button>
      );
    } else {
      if (this.state.verifyingEmailAddress === "pending") {
        button = (
          <button disabled className="navButton sendMessageButtonWait">
            Confirming Email...
          </button>
        );
      } else {
        button = (
          <button
            onMouseUp={this.openMessageModal}
            onTouchEnd={this.openMessageModal}
            className="navButton sendMessageButton"
          >
            Send Message
          </button>
        );
      }
    }

    // Email verification text
    var modalContent;
    // console.log(this.props.appState.user)

    if (
      this.props.appState.userData.emailVerified === undefined ||
      this.props.appState.userData.emailVerified === false
    ) {
      modalContent = (
        <div
          id="sendMessageModal"
          className="modal modal-fixed-footer messageModal"
        >
          <div
            style={{ fontSize: "14pt", textAlign: "center", marginTop: "12px" }}
            className="modal-content noscrollX"
          >
            Please confirm your email address before sending messages. Check
            your inbox for a verification email from Cool Neighbors.
            <br />
            <br />
            <a
              style={{
                backgroundColor: "#b93e2b",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "10pt",
                boxShadow: "2px 2px 5px rgb(179, 179, 179)",
              }}
              onMouseUp={this.resendVerificationEmail}
              onTouchEnd={this.resendVerificationEmail}
              className="modal-close waves-effect waves-red"
            >
              RESEND VERIFICATION EMAIL{" "}
            </a>
          </div>
          <div className="modal-footer">
            <a className="modal-close waves-effect waves-red btn-flat">OK</a>
          </div>
        </div>
      );
    } else {
      modalContent = (
        <div
          id="sendMessageModal"
          className="modal modal-fixed-footer messageModal"
        >
          <div className="modal-content noscrollX">
            <h4 className="modalHeading">Message</h4>
            <textarea
              onChange={this.props.checkQuotes}
              id="messageTextArea"
              ref={this.messageTextArea}
              className="messageTextArea"
            ></textarea>
          </div>
          <div className="modal-footer">
          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
            <a
              onMouseUp={this.props.findCorrespondence}
              onTouchEnd={this.props.findCorrespondence}
              className="modal-close waves-effect waves-red btn red darken-3"
            >
              SEND
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        {/* BUTTON */}
        <div style={{ textAlign: "center", maxWidth: "800px" }}>{button}</div>

        {/* MODAL */}

        {modalContent}
      </div>
    );
  }
}

export default SendMessage;
