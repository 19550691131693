import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
// import SearchResult from './SearchResult'
import ActivitiesModal from './ActivitiesModal'
import HoodsModal from './HoodsModal'
import CityModal from './CityModal'
import SearchResult from './SearchResult'
import { db } from '../../fbConfig'
import { neighborhoods } from '../../cityData/hoods';


class SearchImproved extends Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("nowCity") === null){
      localStorage.setItem("nowCity", "New York City / NY Metropolitan Area");
    }

    this.state = {
      activitiesModal: {},
      hoodsModal: {},
      cityModal: {},
      firstSearchTriggered: false,
      searchResults: null,
      notLoggedIn: false,
      cityNotLoggedIn: localStorage.getItem("nowCity"),
      newCitySearchPending: false
    }

    this.activitiesModalLength = 0;
    this.hoodsModalLength = 0;


  }

  componentDidMount = () => {

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Search | Cool Neighbors";

    if (!this.props.appState.loggedIn){
      this.getCitySearchResults();
      this.setState({
        firstSearchTriggered: true
      })
    }
    else if (this.props.appState.userData.citySearch !== undefined){
      this.getCitySearchResults();
      this.setState({
        firstSearchTriggered: true
      })
    }
    
    


  }

  captureModals = (modal) => {
    this.setState({
      [modal.id]: modal
    })


  }

  getModalLengths = (modal) => {

    if (modal.id === "activitiesModal"){
      var activityInputs = document.querySelectorAll(".activity");
      this.activitiesModalLength = activityInputs.length;
    }
    if (modal.id === "hoodsModal"){
      var hoodInputs = document.querySelectorAll(".hood");
      // console.log(hoodInputs)
      this.hoodsModalLength = hoodInputs.length;
      // console.log(this.hoodsModalLength)
    }

  }

  componentDidUpdate = () => {
    if (!this.props.appState.loggedIn && this.state.firstSearchTriggered === false){
      this.getCitySearchResults();
      this.setState({
        firstSearchTriggered: true
      })
    }
    else if (this.props.appState.userData.citySearch !== undefined && this.state.firstSearchTriggered === false){
      this.getCitySearchResults();
      this.setState({
        firstSearchTriggered: true
      })
    }
  }

  openActivitiesModal = () => {
    if (!this.props.appState.loggedIn) {
      this.setState({
        notLoggedIn: true
      })
    } else {
      this.state.activitiesModal.open();
      var activSearch = this.props.appState.userData.activSearch;
      var activityInputs = document.querySelectorAll(".activity");

      for (var i = 0; i < activityInputs.length; i++){
        for (var j = 0; j < activSearch.length; j++){
          if (activityInputs[i].value === activSearch[j]){
            activityInputs[i].checked = true;
          }
        }
      }

      if (activSearch.length === activityInputs.length){
        var allActivitiesCheckbox = document.querySelector("#allActivities");
        allActivitiesCheckbox.checked = true;
      }
    }
    
  }

  openHoodsModal = () => {

    if (!this.props.appState.loggedIn) {
      this.setState({
        notLoggedIn: true
      })
    } else {
      this.state.hoodsModal.open();
      var hoodSearch = this.props.appState.userData.hoodSearch;
      var hoodsInputs = document.querySelectorAll(".hood");

      // console.log(hoodsSearch)

      for (var i = 0; i < hoodsInputs.length; i++){
        for (var j = 0; j < hoodSearch.length; j++){
          if (hoodsInputs[i].value === hoodSearch[j]){
            hoodsInputs[i].checked = true;
          }
        }
      }

      if (hoodSearch.length === hoodsInputs.length){
        var allHoodsCheckbox = document.querySelector("#allHoods");
        allHoodsCheckbox.checked = true;
      }
    }
  }

  openCityModal = () => {
    this.state.cityModal.open();
    var citySearch;
    if (!this.props.appState.loggedIn){
      citySearch = this.state.cityNotLoggedIn;
    } else {
      citySearch = this.props.appState.userData.citySearch;
    }
    
    var cityInputs = document.querySelectorAll(".city");
    // cityInputs[0].checked = true;
    console.log(citySearch)

    for (var i = 0; i < cityInputs.length; i++){
      if (cityInputs[i].value === citySearch){
        cityInputs[i].checked = true;
      }
    }
  }

  handleActivityChange = (activity) => {
    var activityInputs = document.querySelectorAll(".activity");
    var allActivitiesCheckbox = document.querySelector("#allActivities");

    var listOfCheckedInputs = []
    if (activity.target.value === "all"){
      if(activity.target.checked){
        activityInputs.forEach(input => {
          input.checked = true
          listOfCheckedInputs.push(input.value)
        })
      } else {
        activityInputs.forEach(input => {
          input.checked = false
          listOfCheckedInputs = []
        })
      }
    }
    else {
      activityInputs.forEach(input => {
        if (input.checked){
          listOfCheckedInputs.push(input.value)
        }
      })
    }

    if (listOfCheckedInputs.length === activityInputs.length){
      
      allActivitiesCheckbox.checked = true;
    } else {
      allActivitiesCheckbox.checked = false;
    }
  }

  handleHoodChange = (hood) => {
    var hoodInputs = document.querySelectorAll(".hood");
    var allHoodsCheckbox = document.querySelector("#allHoods");
    // console.log(hood)
    // this.hoodsModalLength = hoodInputs.length;
    // console.log("test")
    var listOfCheckedInputs = []
    if (hood.target.value === "all"){
      console.log("all")
      if(hood.target.checked){
        hoodInputs.forEach(input => {
          input.checked = true
          listOfCheckedInputs.push(input.value)
        })
      } else {
        hoodInputs.forEach(input => {
          input.checked = false
          listOfCheckedInputs = []
        })
      }
    }
    else {
      hoodInputs.forEach(input => {
        if (input.checked){
          listOfCheckedInputs.push(input.value)
        }
      })
    }
    if (listOfCheckedInputs.length === hoodInputs.length){
      allHoodsCheckbox.checked = true;
    } else {
      allHoodsCheckbox.checked = false;
    }
  }

  //UNNECESSARY FUNCTION?
  handleCityChange = (city) => {
    var cities = document.querySelectorAll(".city");

    var selectedCity;
    cities.forEach(input => {
      if (input.checked){
        selectedCity= input.value
      }
    })

    console.log(selectedCity)

  }


  submitActivities = () => {
    this.props.resetSearchPage();
    this.setState({
      searchResults: null
    })

    var activityInputs = document.querySelectorAll(".activity");
    var listOfCheckedInputs = [];
    activityInputs.forEach(input => {
      if (input.checked){
        listOfCheckedInputs.push(input.value)
      }
    })

    var newActivSearch = {
      activSearch: listOfCheckedInputs
    }

    // console.log(newActivSearch)
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(newActivSearch).then(() => {
      this.getCitySearchResults();
    })
  }

  submitHoods = () => {
    this.props.resetSearchPage();
    this.setState({
      searchResults: null
    })

    var hoodInputs = document.querySelectorAll(".hood");
    var listOfCheckedInputs = [];
    hoodInputs.forEach(input => {
      if (input.checked){
        listOfCheckedInputs.push(input.value)
      }
    })

    var newHoodSearch = {
      hoodSearch: listOfCheckedInputs
    }

    // console.log(newActivSearch)
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(newHoodSearch).then(() => {
      this.getCitySearchResults();
    })

  }

  submitCity = () => {
    // this.state.cityModal.close();
    this.props.resetSearchPage();
    this.setState({
      searchResults: null,
      newCitySearchPending:true
    })

    this.hoodsModalLength = "City just changed."

    var cities = document.querySelectorAll(".city");
    var selectedCity;
    cities.forEach(input => {
      if (input.checked){
        selectedCity= input.value
      }
    })

    var cityData = neighborhoods[selectedCity];
    var regions = Object.keys(cityData);
    var listOfHoods = [];
    // console.log(cityData)
    for (var i = 0; i < regions.length; i++){
      var regionHoods = cityData[regions[i]];
      for (var j = 0; j < regionHoods.length; j++){
      var regionHoods = cityData[regions[i]];
        listOfHoods.push(regionHoods[j])
      }
    }
    // console.log(listOfHoods)
    var newCitySearch = {
      citySearch: selectedCity,
      hoodSearch: listOfHoods
    }
    console.log(newCitySearch)

    if (this.props.appState.loggedIn){
      let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
      usersRef.update(newCitySearch).then(() => {
        this.getCitySearchResults();
      })
    } else {

      localStorage.setItem("nowCity", selectedCity);

      this.setState({
        cityNotLoggedIn: selectedCity
      }, this.getCitySearchResults)
    }

  }

  getCitySearchResults = () => {
    
    var citySearch;
    if (this.props.appState.loggedIn){
      citySearch = this.props.appState.userData.citySearch;
    } else {
      citySearch = this.state.cityNotLoggedIn
    }

    let usersRef = db.collection('userData');

    var searchResults = [];
    console.log("searching...")
    var query = usersRef.where("city", "==", citySearch).get()
      .then(querySnapshot => {
        querySnapshot.forEach(thing => {
          searchResults.push(thing.data());
        })
        this.filterResultsByActivitiesAndHoods(searchResults);
        this.setState({
          newCitySearchPending: false
        })

      })
      .catch(err => {
        console.log(err)
      });

  }

  filterResultsByActivitiesAndHoods = (searchResults) => {
    var finalResults = [];

    if (this.props.appState.loggedIn){
      var hoodSearch = this.props.appState.userData.hoodSearch;
      var activSearch = this.props.appState.userData.activSearch;

      var resultsFilteredByHoods = [];
      for (var i = 0; i < searchResults.length; i++){
        
        if (searchResults[i].accountDeleted === false) {
          var thisUsersHoods = searchResults[i].neighborhoods;
          
          if (thisUsersHoods.some(hoodCheck)){
            resultsFilteredByHoods.push(searchResults[i]);
          }
        }
        // userHoods.some(hood => hoodSearch.includes(hood));
      }

      function hoodCheck(item){
        return hoodSearch.includes(item)
      }

      
      for (var i = 0; i < resultsFilteredByHoods.length; i++){
        if (resultsFilteredByHoods[i].accountDeleted === false) {
          var thisUsersActivities = searchResults[i].activities;

          if (thisUsersActivities.some(activCheck)){
            finalResults.push(resultsFilteredByHoods[i]);
          }
        }

      }


      function activCheck(item){
        return activSearch.includes(item)
      }
    } else {
      for (var i = 0; i < searchResults.length; i++){
        if (searchResults[i].accountDeleted === false) {
          finalResults.push(searchResults[i]);
        }
      }

      // finalResults = searchResults
    }

    var date = new Date;
    var day = date.getDay();

    var dayAsPercent = day/7;
    var startingPointInResultsArray = Math.floor(finalResults.length * dayAsPercent);

    var secondHalf = finalResults.slice(startingPointInResultsArray, finalResults.length);
    var firstHalf = finalResults.slice(0, startingPointInResultsArray);

    finalResults = secondHalf.concat(firstHalf);


    


    this.setState({
      searchResults: finalResults
    });

    console.log("Search complete.");
    console.log(finalResults)

  }

  unfoldRegion = (e) => {
    var hoods;
    var target;
    var targetElementClasses;
    var relatedElementClasses;
    if(e.target.tagName === "H5"){
      target = e.target.id
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.children[0].classList;
      if (targetElementClasses.contains("folded")){
        relatedElementClasses.add("unfoldedImg");
      } else {
        relatedElementClasses.remove("unfoldedImg");
      }
    } else {
      target = e.target.alt
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.parentNode.classList;
      targetElementClasses.add("unfoldedImg");
      if (targetElementClasses.contains("folded")){
        targetElementClasses.add("unfoldedImg");
      } else {
        targetElementClasses.remove("unfoldedImg");
      }
    }
    // console.log(relatedElementClasses)
    if (targetElementClasses.contains("folded")){
      targetElementClasses.remove("folded");
      relatedElementClasses.remove("folded");
      hoods.forEach(hood => {
        hood.classList.remove("hide")
      });
    } else {
      targetElementClasses.add("folded");
      relatedElementClasses.add("folded");
      hoods.forEach(hood => {
        hood.classList.add("hide")
      });
    }
  }
  
  decreaseSearchPage = () => {
    this.props.decreaseSearchPage();

    var divider = document.querySelector("#divider");
    divider.scrollIntoView();
    
  }

  increaseSearchPage = () => {
    this.props.increaseSearchPage();

    var divider = document.querySelector("#divider");
    divider.scrollIntoView();

    

    
    console.log("test")

  }


  render() {
    
    if (this.state.notLoggedIn){
      return <Redirect push to="/signup" />
    }
    var activitiesToDisplay;
    if (!this.props.appState.loggedIn){
      activitiesToDisplay = <span style={{fontStyle: "italic", fontWeight: "500"}}>&mdash; All &mdash;</span>
    }
    else if (this.activitiesModalLength === 0){
      activitiesToDisplay = <span></span>
    }
    else if (this.props.appState.userData.activSearch === undefined){
      activitiesToDisplay = <span></span>
    } 
    else if(this.activitiesModalLength === this.props.appState.userData.activSearch.length){
      activitiesToDisplay = <span style={{fontStyle: "italic", fontWeight: "500"}}>&mdash; All &mdash;</span>
    }
    else {
      var activities = this.props.appState.userData.activSearch
      
      if (activities.length > 14){
        activities = activities.slice(0, 14);
        activitiesToDisplay =<span>{activities.join(", ")}<span style={{fontWeight: "700"}}> . . .</span></span>
      } else {
        activitiesToDisplay = activities.join(", ")
      }
    }

    var hoodsToDisplay;
    // console.log(this.hoodsModalLength)
    if (!this.props.appState.loggedIn){
      // console.log("1")
      hoodsToDisplay = <span style={{fontStyle: "italic", fontWeight: "500"}}>&mdash; All &mdash;</span>
    }
    else if (this.hoodsModalLength === 0){
      // console.log("2")
      hoodsToDisplay = <span></span>
    }
    else if (this.props.appState.userData.hoodSearch === undefined){
      // console.log("3")
      hoodsToDisplay = <span></span>
    } 
    else if (this.hoodsModalLength === "City just changed."){
      // console.log("4")
      hoodsToDisplay = <span style={{fontStyle: "italic", fontWeight: "500"}}>&mdash; All &mdash;</span>
    }
    else if(this.hoodsModalLength === this.props.appState.userData.hoodSearch.length){
      // console.log("5")
      hoodsToDisplay = <span style={{fontStyle: "italic", fontWeight: "500"}}>&mdash; All &mdash;</span>
    }
    else {
      if (!this.state.newCitySearchPending){
        // console.log("6")
        var hoods = this.props.appState.userData.hoodSearch
        if (hoods.length > 14){
          hoods = hoods.slice(0, 14);
          hoodsToDisplay =<span>{hoods.join(", ")}<span style={{fontWeight: "700"}}> . . .</span></span>
        } else {
          hoodsToDisplay = hoods.join(", ")
        }
      }
    }

    var cityToDisplay;
    if (!this.props.appState.loggedIn){
      cityToDisplay = this.state.cityNotLoggedIn;
    }
    else if (this.props.appState.userData.citySearch){
      cityToDisplay = this.props.appState.userData.citySearch;
    } else {
      cityToDisplay = <span></span>
    }


    var {searchResults} = this.state;
    var searchResultsHTML;
    var startingIndexNumber;
    var endingIndexNumber;
    var keyOfLastHTMLResultShown;

    if (searchResults === null){
      searchResultsHTML = <div style={{fontSize: "14pt", fontWeight: "400", maxWidth: "800px"}}>Search results loading...</div>
    }
    else if (searchResults.length === 0){
      searchResultsHTML = <div style={{fontSize: "14pt", fontWeight: "400"}}>No results found.</div>
    }
    else {
      
      searchResultsHTML = searchResults.map((user, index)=> {
        startingIndexNumber = this.props.appState.searchPage * 10
        endingIndexNumber = (this.props.appState.searchPage + 1) * 10
        
        if ((index >= startingIndexNumber) && (index < endingIndexNumber)){
          return(
            <SearchResult key={index + 1} name={user.firstName} pic={user.picURL} about={user.about} uid={user.uid}/>
          )
        }
        else {
          return null
        }
        
      });
      searchResultsHTML = searchResultsHTML.filter(node => {
        return node != null;
      });
      keyOfLastHTMLResultShown = parseInt(searchResultsHTML[searchResultsHTML.length-1].key)
    }


    var nextBackButtons;
    if (this.state.searchResults !== null ){
      if (this.state.searchResults.length < 1){
        nextBackButtons = <div>

          <button className="nextBackButton searchButton" disabled>Back</button>&emsp;

          <button className="nextBackButton searchButton" disabled>Next</button>
          
        </div>
      } else {
        nextBackButtons = <div>

          <button onMouseUp={this.decreaseSearchPage} onTouchEnd={this.decreaseSearchPage} className="nextBackButton searchButton" disabled={this.props.appState.searchPage < 1 ? true : false}>Back</button>&emsp;

          <button onMouseUp={this.increaseSearchPage} onTouchEnd={this.increaseSearchPage} className="nextBackButton searchButton" disabled={searchResults.length === keyOfLastHTMLResultShown ? true : false}>Next</button>
          
        </div>
      }
    }
    

    return (
      <div style={{maxWidth: "800px"}}>
          <div id="searchHeading" className="searchHeading">Search</div>

          <button onMouseUp={this.openActivitiesModal} onTouchEnd={this.openActivitiesModal} disabled={this.props.appState.loggedIn ? "" : true}
         className="searchButton">Select</button>

          <span style={{fontSize: "13pt", fontWeight: "700"}}>Interests & Activities:</span><br/>
          <div className="searchSelected">
            {activitiesToDisplay}
            
          </div>

          <button disabled={this.props.appState.loggedIn ? "" : true} onMouseUp={this.openHoodsModal} onTouchEnd={this.openHoodsModal} className="searchButton">Select</button>

          <span style={{fontSize: "13pt", fontWeight: "700"}}>Neighborhoods:</span><br/>
          <div className="searchSelected">
            {hoodsToDisplay}
            
          </div>

          <button onMouseUp={this.openCityModal} onTouchEnd={this.openCityModal} className="searchButton">Select</button>
          <span style={{fontSize: "13pt", fontWeight: "700"}}>City:</span>
          <div className="searchSelected">
            {cityToDisplay}

          </div>

          <div id="divider" style={{maxWidth: "770px"}} className="divider"></div><br/>

          <div style={{textAlign: "center"}}>

            {searchResultsHTML}
            <br/>
            
            {nextBackButtons}

            <br/><br/><br/><br/><br/><br/><br/>

          </div>

          <ActivitiesModal getModalLengths={this.getModalLengths} submitActivities={this.submitActivities} handleActivityChange={this.handleActivityChange} captureModals={this.captureModals} appState={this.props.appState}/>

          <HoodsModal unfoldRegion={this.unfoldRegion} getModalLengths={this.getModalLengths} submitHoods={this.submitHoods} handleHoodChange={this.handleHoodChange} captureModals={this.captureModals} appState={this.props.appState}/>

          <CityModal cityNotLoggedIn={this.state.cityNotLoggedIn} submitCity={this.submitCity} handleCityChange={this.handleCityChange} captureModals={this.captureModals} appState={this.props.appState}/>

      </div>
    )

  }
}

export default SearchImproved;