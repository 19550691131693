import React, {Component} from 'react'
import building from '../images/neighborhood.jpg'
import apple from '../images/apple.png'
import android from '../images/android.png'
import { Link } from 'react-router-dom'

class Home extends Component {

  componentDidMount = () => {
    // var scrollHere = document.querySelector("#scrollHere");
    // scrollHere.scrollIntoView();
    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      console.log(e)
      content.scrollTop = 0;
      window.scrollTop = 0;
    }


    this.props.clearSignup();

    document.title = "Cool Neighbors";

  }

  handleClick = (e) => {
    console.log(e.target)

  }

  render() {

    let textClass;
    var getStartedButton = <span></span>;
    if (this.props.appState.appContainerClass === "appContainerSmall"){
      textClass = "largerText para";
      
    } 
    else { 
      textClass = "flow-text para";
    }

    if (!this.props.appState.loggedIn){
      getStartedButton = <Link to="/signup"><button className="getStarted">Get Started</button></Link>
      if (this.props.appState.appContainerClass === "appContainerSmall"){
        getStartedButton = <button onTouchEnd={this.props.navOpen} onMouseUp={this.props.navOpen} className="getStarted">Get Started</button>
      }
    }

    return(
      <div>
        <img className="frontImg" src={building} alt="building" />
        <p className={textClass}>Cool Neighbors is a friendship site, not a dating site.&ensp;It's a place to safely and easily meet new people in your neighborhood and your city.&ensp;And it's totally free.
        </p>
        <p className={textClass}>Cool Neighbors works well on both mobile and desktop web browsers.&ensp;Android and iOS apps are also available.&ensp;
        </p>
        <br/><br/>
        <div style={{textAlign: "center", width: "100%", maxWidth: "850px"}}>
        {getStartedButton}

        <br/><br/>
        <a href="https://apps.apple.com/us/app/cool-neighbors/id1543567651#?platform=iphone">
        <img className={this.props.appState.loggedIn ? "appleAppIconLoggedIn" : "appleAppIcon"} src={apple} alt="apple" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.coolneighbors">
        <img className={this.props.appState.loggedIn ? "androidAppIconLoggedIn" : "androidAppIcon"} src={android} alt="android" /></a>
        </div>
        
      
        <br/><br/><br/><br/><br/><br/>
        
        <div style={{fontSize: "13pt", fontWeight: "400", textAlign: "center", maxWidth: "850px"}}>Cool Neighbors was founded by a <Link style={{color: "#b93e2b", fontWeight: "500"}} to="/HiVgadPUqgZNlydAIA6TevzDaY02">schoolteacher.</Link>
        <br/>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="RNJBPREZ4KK2S" />
        <input className="donateButton" type="submit" value="• DONATE •" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        {/* <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" /> */}
        </form></div>

        
        
        <br/><br/><br/>



      </div>
    )
  }
}

export default Home;