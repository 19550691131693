import React, { Component } from 'react'
import buildings from '../images/buildings-trees.jpg'
import { auth, functions } from '../fbConfig'
import { storage } from '../fbConfig'
import { db } from '../fbConfig'
import { Redirect } from 'react-router-dom'
import closeImg from "../images/close.png"
import SignupModal from "./SignupModal"
import M from 'materialize-css';
import { neighborhoods } from "../cityData/hoods.js"
import { activities } from "../cityData/activities.js"
import {caps} from "../images/captchas/captchas"
import Jimp from "jimp"
import PrivacyPolicy from "./PrivacyPolicy"
import wordsCounter from 'word-counting'
import EULAmodal from './EULAmodal'


class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      picAdded: false,
      userImage: null,

      modalData: {},
      city: "",
      hoods: [],
      firstName: "",
      activities: [],
      capNumber: Math.floor(Math.random() * 20),
      picURL: "blankPic.png",
      capCounter: 0,
      neighborhoodsChecked: 0,
      privacyChecked: false,
      uploadingImage: false,
      wordCount: 0
    }
  }

  checkQuotes = (e) => {
    e.target.value = e.target.value.replace(/\ "/g, " “");
    var textTyped = e.target.value
    var w = wordsCounter(e.target.value)
    // console.log(w.wordsCount)

    this.setState({
      wordCount: w.wordsCount
    })

    sessionStorage.setItem('aboutTextTyped', textTyped);

  }

  countWords = (str) => {
    // var count = 0;
    // for (var i=0; i <= str.length; i++) {
    //    if (str.charAt(i) == " ") {
    //         count ++;
    //     }
    // }
    // console.log(count + 1)
    // return count + 1;

    var w = wordsCounter(str)
    // console.log(w.wordsCount)

    return w.wordsCount

  }

  checkFields = () => {
    this.props.signupPendingOn();
    let form = document.querySelector(".form");
    let inputs = form.querySelectorAll("input, textarea");
    //let capImg = document.querySelector(".captcha");
    let formValues = {};
    inputs.forEach((input) =>
      formValues[input.name] = input.value
    );
    formValues.capNumber = this.state.capNumber;

    if (formValues.firstName.length < 1) {
      alert("Please enter a first name.");
      this.props.signupPendingOff();
    } else if (formValues.city.length < 1) {
      alert("Please select a city.");
      this.props.signupPendingOff();
    } else if (formValues.hoods.length < 1){
      alert("Please select some neighborhoods.");
      this.props.signupPendingOff();
    } else if (this.state.neighborhoodsChecked > 20){
      alert("Please select 20 or fewer neighborhoods.");  
      this.props.signupPendingOff();
    } else if (formValues.activities.length < 1){
      alert("Please select some activities.");
      this.props.signupPendingOff();
    } else if (this.countWords(formValues.about) < 35){
      alert("Please type at least 35 words about yourself.")
      this.props.signupPendingOff();
    } else if (!this.state.picAdded){
      alert("Please include a picture of yourself.")
      this.props.signupPendingOff();  
    } else if (formValues.email.length < 1){
      alert("Please enter an email address.");
      this.props.signupPendingOff();
    } else if (formValues.checkEmail.length < 1){
      alert("Please retype your email address.");
      this.props.signupPendingOff();
    } else if (formValues.email !== formValues.checkEmail){
      alert("Your emails do not match.");
      this.props.signupPendingOff();
    } else if (formValues.password.length < 1){
      alert("Please enter a password.");
      this.props.signupPendingOff();
    } else if (formValues.checkPassword.length < 1){
      alert("Please retype your password.");
      this.props.signupPendingOff();
    } else if (formValues.password !== formValues.checkPassword){
      alert("Your passwords do not match.");
      this.props.signupPendingOff();
    } else if (formValues.code.length < 1){
      this.setState({
        capCounter: this.state.capCounter + 1
      })
      alert("Please enter the code.");
      this.props.signupPendingOff();
    } else if (!this.state.privacyChecked) {
      alert("Please agree to the privacy policy.");
      this.props.signupPendingOff();
    } else {
      this.checkCode(formValues);
    }
  }

  checkCode = (formValues) => {
    const checkCaptcha = functions.httpsCallable('checkCaptcha');
    checkCaptcha({
      capNumber: this.state.capNumber,
      code: formValues.code
    }).then(result => {
      // Read result of the Cloud Function.
      // var sanitizedMessage = result.data.text;
      if (result.data.match){
        this.checkAuth(formValues);
      } else {
        this.setState({
          capCounter: this.state.capCounter + 1
        })
        alert("You did not correctly type the code.");
        this.props.signupPendingOff();
      }
    }).catch(err => {
      console.log(err);
      this.props.signupPendingOff();
    });
  }

  checkAuth = (formValues) => {
    const email = formValues.email;
    const password = formValues.password;
    
    auth.createUserWithEmailAndPassword(email, password)
      .then(cred => {
        // console.log(cred)

        if (this.state.userImage){
          this.uploadPic(this.state.userImage, cred.user.uid, formValues);
        } else {
          var userId = cred.user.uid;
          this.createProfileData(userId, formValues);
        }
      })
      .catch(err => {
          alert(err.message)
          var badUser = auth.currentUser;
          if (badUser !== null){
            badUser.delete();
          }
          this.props.signupPendingOff();
      });

  }

  createProfileData = (userId, formValues) => {
    //console.log(cred.user.uid)
    formValues.hoods = formValues.hoods.split(", ");
    formValues.activities = formValues.activities.split(", ");

    var myCityData = neighborhoods[formValues.city];
    var regions = Object.keys(myCityData);
    var listOfAllHoods = [];
    for (var i = 0; i < regions.length; i++){
      var region = regions[i];
      var hoodsInRegion = myCityData[region];
      for (var j = 0; j < hoodsInRegion.length; j++){
        listOfAllHoods.push(hoodsInRegion[j]);
      }
    }
    // console.log(listOfAllHoods);
    var emailVerifyCode = Math.floor(Math.random() * 1000000);

    db.collection('userData').doc(userId).set({
      firstName: formValues.firstName,
      city: formValues.city,
      neighborhoods: formValues.hoods,
      activities:  formValues.activities,
      about:  formValues.about,
      activSearch: activities,
      hoodSearch: listOfAllHoods,
      citySearch: formValues.city,
      nowCity:  formValues.city,
      picURL: this.state.picURL,
      uid: userId,
      receiveNotifications: true,
      accountDeleted: false,
      flagged: [],
      emailVerifyCode: emailVerifyCode
    }).then(() => {

        this.props.finishSignup(emailVerifyCode, userId, formValues.firstName);

      })  
      .catch(err => {
        this.props.signupPendingOff();
        alert(err.message)
      });
  }

  uploadPic = (pic, userId, formValues) => {
    var storageRef = storage.ref(userId);
    storageRef.putString(pic, 'data_url')
      .then(() => {
          storageRef.getDownloadURL().then((url) => {
            this.setState({
              picURL: url
            })
            this.createProfileData(userId, formValues);
          }).catch(err => {
            // this.props.signupPending();
            alert(err.message);
            this.createProfileData(userId, formValues);
          });
        }
      )
      .catch(err => {
        // this.props.signupPending();
        alert(err.message);
        this.createProfileData(userId, formValues);
      });
  }

  readPic = () => {
    this.setState({
      uploadingImage: true
    })
    var userImage = document.getElementById('imageUpload').files[0];
    // console.log(userImage)

    // faceapi.bufferToImage(userImage).then((result)=> {
    //   console.log(result)
    // })

    // faceapi.detectAllFaces(userImage).then((result)=> {
    //   console.log(result)
    // })
    

    var reader = new FileReader();
    reader.readAsDataURL(userImage);
    reader.onload = () => {
      Jimp.read(reader.result, (err, img) => {
        if (err) {
          alert(err);
          this.setState({
            uploadingImage: false
          })
        } else {
          var resizedImage = img.resize(800, Jimp.AUTO).quality(85);
          // console.log(resizedImage)
          resizedImage.getBase64Async(resizedImage.getMIME())
            .then(imgData => {
              console.log(imgData);
              this.setState({
              picAdded: true,
              userImage: imgData,
              uploadingImage: false
            })
          })
        }
      });
    }
    // console.log(userImage)
  }


  deletePic = () => {
    this.setState({
      picAdded: false,
      userImage: null
    })
  }



  componentDidMount = () => {

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Sign-Up | CoolNeighbors";

    var modal = document.querySelector('#signupModal');
    var modalOptions = {}
    this.modal = M.Modal.init(modal, modalOptions);

    var privacyPolicyModal = document.querySelector('#privacyPolicy');
    var eulaModal = document.querySelector('#eulaModal');
    var modalOptions = {}
    this.ppModal = M.Modal.init(privacyPolicyModal, modalOptions);
    this.eModal = M.Modal.init(eulaModal, modalOptions);

    if (sessionStorage.getItem('aboutTextTyped')){
      document.querySelector("#about").value = sessionStorage.getItem('aboutTextTyped')
    }

    // faceapi.nets.ssdMobilenetv1.loadFromUri('/models').then(result => {
    //   console.log(result);
    // }).catch(error => {
    //   console.log(error)
    // })

}

  componentDidUpdate = () => {
    // var modal = document.querySelector('#signupModal');
    // var modalOptions = {}
    // this.modal = M.Modal.init(modal, modalOptions);
  }

  modalOpen = (e) => {
    // console.log(e.target.name);
    var modalData;
    var cities = Object.getOwnPropertyNames(neighborhoods);
    if (e.target.name === "city"){
      modalData = {
        heading: "Your City",
        data: cities,
        doneFunction: this.getCity
      }
      this.setState({
        modalData
      })
      this.modal.open();
    }
    if (e.target.name === "hoods"){
      if (this.state.city !== ""){
        var hoods = neighborhoods[this.state.city];
        modalData = {
          heading: "Neighborhoods",
          data: hoods,
          doneFunction: this.getHoods
        }
        this.setState({
          modalData
        })
        this.modal.open();
      }
      else {
        document.getElementById("hoodInput").blur(); 
        alert("Choose your city first.");
      }
    }


    if (e.target.name === "activities"){
      modalData = {
        heading: "Activities",
        data: activities,
        doneFunction: this.getActivities
      }
      this.setState({
        modalData
      })
      this.modal.open();
    }

    document.querySelector("#modalContent").scrollTop = 0;
    

  }

  getCity = () => {

    let cities = document.querySelectorAll(".city");
    let selectedCity = "";
    cities.forEach(city => {
      if (city.checked === true){
        selectedCity = city.value;
      }
    })
    this.setState({
      city: selectedCity,
      hoods: [],
      neighborhoodsChecked: 0
    })
  }

  getHoods = () => {
    let hoods = document.querySelectorAll(".hood");
    let selectedHoods = [];
    hoods.forEach(hood => {
      if (hood.checked === true){
        selectedHoods.push(hood.value);
      }
    })
    // selectedHoods = selectedHoods.join(", ")
    this.setState({
      hoods: selectedHoods
    })

    
  }

  getActivities = () => {
    let activs = document.querySelectorAll(".activity");
    //console.log(activs)
    let selectedActivs = [];
    activs.forEach(activity => {
      if (activity.checked === true){
        selectedActivs.push(activity.value)
      }
    })
    // selectedActivs = selectedActivs.join(", ")
    this.setState({
      activities: selectedActivs
    })
    
  }


  handleChange = (e) => {
    if (e.target.name === "firstName"){
      this.setState({
        firstName: e.target.value
      })
    }
  }

  countNumberChecked = (e) => {
    if(e.target.checked){
      this.setState({
        neighborhoodsChecked: this.state.neighborhoodsChecked + 1
      }, () => {
        // console.log(this.state.neighborhoodsChecked)
        if (this.state.neighborhoodsChecked > 20){
          alert("There is a maximum of 20 neighborhoods.")
        }
      })
    } else {
      this.setState({
        neighborhoodsChecked: this.state.neighborhoodsChecked - 1
      }, () => {
        // console.log(this.state.neighborhoodsChecked)
      })
    }
  }

  agreeToPrivacyPolicy = () => {
    this.setState({
      privacyChecked: !this.state.privacyChecked
    })
  }

  agreeToPrivacyPolicyClick = () => {
    this.agreeToPrivacyPolicy();
  }

  showPrivacyPolicy = () => {
    this.ppModal.open();
  }

  showEULA = () => {
    this.eModal.open();
  }

  unfoldRegion = (e) => {
    var hoods;
    var target;
    var targetElementClasses;
    var relatedElementClasses;
    if(e.target.tagName === "H5"){
      target = e.target.id
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.children[0].classList;
      if (targetElementClasses.contains("folded")){
        relatedElementClasses.add("unfoldedImg");
      } else {
        relatedElementClasses.remove("unfoldedImg");
      }
    } else {
      target = e.target.alt
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.parentNode.classList;
      targetElementClasses.add("unfoldedImg");
      if (targetElementClasses.contains("folded")){
        targetElementClasses.add("unfoldedImg");
      } else {
        targetElementClasses.remove("unfoldedImg");
      }
    }
    // console.log(relatedElementClasses)
    if (targetElementClasses.contains("folded")){
      targetElementClasses.remove("folded");
      relatedElementClasses.remove("folded");
      hoods.forEach(hood => {
        hood.classList.remove("hide")
      });
    } else {
      targetElementClasses.add("folded");
      relatedElementClasses.add("folded");
      hoods.forEach(hood => {
        hood.classList.add("hide")
      });
    }
  }


  render() {
    if (this.props.appState.successfulSignup === true){
      return <Redirect to="/profile" />
    } 
    else if (this.state.capCounter === 4) {
      return <Redirect to="/" />
    }
    else {

      return(
        <div>
          <div className="form">
            <img className="frontImg" src={buildings} alt="building" />
            <h2 className="heading">Join the fun!</h2>
            <input onChange={this.handleChange} className="browser-default" type="text" name="firstName" placeholder="First Name" maxLength="26"/><br/>


            <input id="cityInput" onFocus={this.modalOpen} onChange={this.handleChange} value={this.state.city} className="browser-default" type="text" name="city" placeholder="City" maxLength="26"/><br/>
            
            <textarea id="hoodInput" onFocus={this.modalOpen} onChange={this.handleChange} value={this.state.hoods.join(", ")} className="browser-default" type="text" name="hoods" placeholder="Neighborhoods (Choose 1-20)" /><br/>

            <textarea onFocus={this.modalOpen} onChange={this.handleChange} value={this.state.activities.join(", ")} className="browser-default" type="text" name="activities" placeholder="Activities that interest you!"/><br/>


            <textarea id="about" onChange={this.checkQuotes} className="browser-default aboutYourself" type="text" name="about" placeholder="Describe yourself.  Who would you like to meet? Minimum: 35 words." maxLength="2500"/><br/>
            <div style={{fontSize: "11.5pt", margin: "10px", fontWeight: 400, marginTop: "-6px", fontStyle: "italic"}}>Words: {this.state.wordCount}</div><br />

            <div className="uploadPicDiv">

            <input onChange={this.readPic} id="imageUpload" className="hide" type="file" name="pic" accept="image/*"/>

            <label htmlFor="imageUpload" className={`${this.state.uploadingImage ? "uploadImagePleaseWait" : "uploadImage"} ${this.state.picAdded ? "hide" : ""}`}>{this.state.uploadingImage ? "Please Wait" : "Upload a Picture"}</label>
            
            <span className={this.state.picAdded ? "hide" : ""} style={{color: "#666666", fontSize: "11pt"}}>Required.<br/> Your new friends need to see what you look like!</span>

            <img id="uploadPic" alt="uploadPic" className={this.state.picAdded ? "uploadedPic" : "hide"} src={this.state.userImage} /> 

            <img alt="closePic" style={{width: "25px", marginLeft: "5px", cursor: "pointer"}} className={this.state.picAdded ? "" : "hide"} src={closeImg} onMouseUp={this.deletePic} onTouchEnd={this.deletePic} />

            </div>
            <br/>

            <div style={{fontSize: "11.5pt", marginLeft: "10px", marginBottom: "5px", fontStyle: "italic", fontWeight: 400}}>Your email address will <span style={{fontWeight:"600"}}>never</span> be shared with third parties or other Cool Neighbors users.</div>
            <input className="browser-default" type="email" name="email" placeholder="Email"/><br/>

            <input className="browser-default" type="email" name="checkEmail" placeholder="Repeat Email"/><br/><br/>


            <input className="browser-default" type="password" name="password" placeholder="Password"/><br/>

            <input className="browser-default" type="password" name="checkPassword" placeholder="Repeat Password"/><br/>
            


            <div style={{fontSize: "14pt", margin: "10px", fontWeight: 400, fontStyle: "italic"}}>Enter the code below to prove you're not a robot:<br />

            <img src={caps[this.state.capNumber]} alt={`${this.state.capNumber}`} className="captcha"/>

            </div>

            <input maxLength="8" className="browser-default" type="text" name="code" placeholder="Code"/><br/>

            <div style={{fontSize: "14pt", fontWeight: 400, margin: "10px", fontStyle: "italic", marginTop: "20px"}}>
              
            <label onClick={this.agreeToPrivacyPolicyClick} htmlFor="agree">

              <input onChange={this.agreeToPrivacyPolicy} checked={this.state.privacyChecked} id="privacyAgree" type="checkbox" value="agree" />

              <span className="privacy" style={{fontSize: "14pt", fontWeight: 400, lineHeight: "28px"}}>
                Yes, I agree to the Cool Neighbors <span style={{color: "#b93e2b", fontWeight: "500"}} onMouseUp={this.showPrivacyPolicy} onTouchEnd={this.showPrivacyPolicy}>privacy policy</span> and <span style={{color: "#b93e2b", fontWeight: "500"}} onMouseUp={this.showEULA} onTouchEnd={this.showEULA}>license agreement</span>.
                </span>
              
            </label>
            
            </div>

              <br/>

            <div className="submitDiv"><button onMouseUp={this.checkFields} onTouchEnd={this.checkFields} className="navButton submitSignup z-depth-1" disabled={this.props.appState.signupPending ? true : false}>{this.props.appState.signupPending ? "Please wait..." : "Submit" }</button><br/><br/></div>
          </div>
        
        <SignupModal appState={this.props.appState} unfoldRegion={this.unfoldRegion} countNumberChecked={this.countNumberChecked} signupState={this.state} />

        <PrivacyPolicy />
        <EULAmodal />
        </div>
      )
    }
  }
}

export default SignUp;