import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { db, storage } from '../../fbConfig'
import M from 'materialize-css'
import edit from "../../images/editIcon.png"
import Name from "./Name"
import About from "./About"
import ActivitiesModal from "./ActivitiesModal"
import CityModal from "./CityModal"
import HoodsModal from "./HoodsModal"
import VerificationReminder from "./VerificationReminder"
import DesktopImage from "./DesktopImage"
import MobileImage from "./MobileImage"
import Jimp from "jimp" 
import { Link } from 'react-router-dom'
import { functions } from '../../fbConfig'
import wordsCounter from 'word-counting'

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingName: false,
      editingAbout: false,
      processingImage: false,
      wordCount: 0,
    }

    this.neighborhoodsChecked = 0;

  }

  componentDidMount = () => {

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }
    

    document.title = "Profile | Cool Neighbors";

    var modals = document.querySelectorAll('.modal');
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);

    var materialImages = document.querySelectorAll('.materialboxed');
    var imageOptions = {};
    this.materialBoxInstances = M.Materialbox.init(materialImages, imageOptions);

    if(this.props.appState.needToShowEmailReminder && this.modals.length > 0){
      this.modals[3].open();
      this.props.toggleVerificationReminder();
    }

  }

  componentDidUpdate = () => {

    if (this.modals.length === 0){
      var modals = document.querySelectorAll('.modal');
      var modalOptions = {};
      this.modals = M.Modal.init(modals, modalOptions);

    }
    

    var materialImages = document.querySelectorAll('.materialboxed');
    var imageOptions = {};
    this.materialBoxInstances = M.Materialbox.init(materialImages, imageOptions);

    if(this.props.appState.needToShowEmailReminder && this.modals.length > 0){
      this.modals[3].open();
      this.props.toggleVerificationReminder();
    }

  }

  checkAbout = (e) => {
    if (e === undefined){
      var currentValue = document.querySelectorAll(".editAboutInput")[0].value
      var w = wordsCounter(currentValue)

      this.setState({
        wordCount: w.wordsCount
      })
    }
    else {
      var userInput = e.target.value
      var changedValue = userInput.replace(/\ "/g, " “")
      e.target.value = changedValue
      var w = wordsCounter(changedValue)

      this.setState({
        wordCount: w.wordsCount
      })
    } 
  }

  openActivitiesModal = () => {
    this.modals[0].open();
  }

  openCityModal = () => {
    this.modals[1].open();
  }

  openHoodsModal = () => {
    this.modals[2].open();
    var hoodInputs = document.querySelectorAll(".hood")
    // console.log(hoodInputs)
    this.neighborhoodsChecked = 0;
    hoodInputs.forEach(hood => {
      if (hood.checked){
        this.neighborhoodsChecked++;
      }
    })
  }


  toggleEditName = () => {
    this.setState({
      editingName: !this.state.editingName
    });
  }

  toggleEditAbout = () => {
    this.setState({
      editingAbout: !this.state.editingAbout
    });

    this.checkAbout();
  }


  submitName = () => {
    var nameInput = document.querySelector(".editNameInput");
    var newName = {
      firstName: nameInput.value
    }
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(newName);
    this.toggleEditName();
  }

  submitActivities = () => {
    var activitiesInputs = document.querySelectorAll(".activity");
    var checkedActivs = [];
    activitiesInputs.forEach(activity => {
      if (activity.checked){
        checkedActivs.push(activity.value);
      }
    })
    // checkedActivs = checkedActivs.join(", ")
    var newActivities = {
      activities: checkedActivs
    }
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(newActivities);
  }



  submitAbout = () => {
    if (this.state.wordCount < 25){
      alert('Your "About" section must contain at least 25 words.')
    } else {
      var aboutInput = document.querySelector(".editAboutInput");

    // var aValue = aboutInput.value

    var userValue = aboutInput.value
    // var changedValue = userValue.replace(/\ "/g, " “")

    var newAbout = {
      about: userValue
    }
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(newAbout);
    this.toggleEditAbout();
    }
  }

  submitCity = () => {
    var cityInputs = document.querySelectorAll(".city");

    let selectedCity = "";
    cityInputs.forEach(city => {
      if (city.checked){
        selectedCity = city.value;
      }
    })

    if (selectedCity !== this.props.appState.userData.city && selectedCity !== ""){
      var newCity = {
        city: selectedCity,
        neighborhoods: []
      }

      let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
      usersRef.update(newCity);
    }
  }

  submitHoods = () => {
    if (this.neighborhoodsChecked > 20){
      alert("There is a maximum of 20 neighborhoods.")
    } else {
      var hoodsInputs = document.querySelectorAll(".hood");
      var checkedHoods = [];
      hoodsInputs.forEach(hood => {
        if (hood.checked){
          checkedHoods.push(hood.value);
        }
      })
      // checkedHoods = checkedHoods.join(", ")
      var newHoods = {
        neighborhoods: checkedHoods
      }
      let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
      usersRef.update(newHoods);
    }
  }


  deletePic = () => {

    this.setState({
      processingImage: true
    })

    var newURL = {
      picURL: "blankPic.png"
    }
    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);

    usersRef.update(newURL).then(() => {
      var storageRef = storage.ref(this.props.appState.user.uid);
      storageRef.delete().then(() => {
      this.setState({
        processingImage: false
      }) 
      }).catch(error => {
        console.log(error);
      });
    });
  }

  readPic = () => {


    this.setState({
      processingImage: true
    })
    var userImage = document.getElementById('uploadPic').files[0];

    var reader = new FileReader();
    reader.readAsDataURL(userImage);

    reader.onload = () => {

      Jimp.read(reader.result, (err, img) => {

        if (err) {
          console.log(err);
        } else {
          // console.log(reader.result.length)
          var resizedImage = img.resize(800, Jimp.AUTO).quality(85);

          resizedImage.getBase64Async(resizedImage.getMIME())
            .then( (imgData ) => {
              this.uploadPic(imgData);
          })
        }
      })
    }
  }

  uploadPic = (imgData) => {
    var storageRef = storage.ref(this.props.appState.user.uid);
    storageRef.putString(imgData, 'data_url')
      .then((info) => {
          this.getPicURL();
        }
      )
      .catch(err => {
        console.log(err.message);
      });
  }

  getPicURL = () => {
    var storageRef = storage.ref(this.props.appState.user.uid);
    storageRef.getDownloadURL().then((url) => {
      var newURL = {
        picURL: url
      }
      let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
      usersRef.update(newURL).then(() => {
        this.setState({
          processingImage: false
        })
      });
    }).catch(err => {
      alert(err.message);
    });
  }

  countNumberChecked = (e) => {
    if(e.target.checked){
      // this.setState({
        // neighborhoodsChecked: this.state.neighborhoodsChecked + 1
      // }, () => {
        // console.log(this.state.neighborhoodsChecked)

        this.neighborhoodsChecked++;
        console.log(this.neighborhoodsChecked);

        if (this.neighborhoodsChecked > 20){
          alert("There is a maximum of 20 neighborhoods.")
        }
      // })
    } else {
      // this.setState({
        // neighborhoodsChecked: this.state.neighborhoodsChecked - 1
      // }, () => {
        // console.log(this.state.neighborhoodsChecked)
      // })
      this.neighborhoodsChecked--;
      console.log(this.neighborhoodsChecked);

    }
  }


  unfoldRegion = (e) => {
    var hoods;
    var target;
    var targetElementClasses;
    var relatedElementClasses;
    if(e.target.tagName === "H5"){
      target = e.target.id
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.children[0].classList;
      if (targetElementClasses.contains("folded")){
        relatedElementClasses.add("unfoldedImg");
      } else {
        relatedElementClasses.remove("unfoldedImg");
      }
    } else {
      target = e.target.alt
      hoods = document.querySelectorAll("." + target)
      targetElementClasses = e.target.classList;
      relatedElementClasses = e.target.parentNode.classList;
      targetElementClasses.add("unfoldedImg");
      if (targetElementClasses.contains("folded")){
        targetElementClasses.add("unfoldedImg");
      } else {
        targetElementClasses.remove("unfoldedImg");
      }
    }
    // console.log(relatedElementClasses)
    if (targetElementClasses.contains("folded")){
      targetElementClasses.remove("folded");
      relatedElementClasses.remove("folded");
      hoods.forEach(hood => {
        hood.classList.remove("hide")
      });
    } else {
      targetElementClasses.add("folded");
      relatedElementClasses.add("folded");
      hoods.forEach(hood => {
        hood.classList.add("hide")
      });
    }
  }



  render() {

    var userData = this.props.appState.userData

    if (this.props.appState.loggedIn === null){
      return <Redirect to="/signin" />
    }
    else if (userData.uid === undefined) {
      return <span></span>
    }
    else {
        return(
        <div style={{maxWidth: "850px"}} >

          <DesktopImage deletePic={this.deletePic} profileState={this.state} readPic={this.readPic} appState={this.props.appState} />
          
          <div className={this.props.appState.appContainerClass === "appContainerSmall" ? "profileSmall" : "profileLarge"}>

            {/* NAME COMPONENT */}
            <Name editingName={this.state.editingName} toggleEditName={this.toggleEditName} userData={userData} appState={this.props.appState} submitName={this.submitName} />

            <MobileImage deletePic={this.deletePic} profileState={this.state} readPic={this.readPic} appState={this.props.appState} />

            {/* ABOUT COMPONENT */}
            <About wordCount={this.state.wordCount} checkAbout={this.checkAbout} editingAbout={this.state.editingAbout} toggleEditAbout={this.toggleEditAbout} userData={userData} appState={this.props.appState} submitAbout={this.submitAbout}/>
            
            {/* CODE TO OPEN ACTIVITIES MODAL */}
            <div className="valign-wrapper">
              <img alt="editIcon" onMouseUp={this.openActivitiesModal} onTouchEnd={this.openActivitiesModal} src={edit} className="editIcon" />
            <span className={this.props.appState.appContainerClass === "appContainerSmall" ? "profileHeadingSmall" : "profileHeadingLarge"}>Interests & Activities:</span>
            </div>
            <div className="profileText">{userData.activities.join(", ")}</div>
            
            {/* CODE TO OPEN CITY MODAL */}
            <div className="valign-wrapper">
              <img alt="editIcon" onMouseUp={this.openCityModal} onTouchEnd={this.openCityModal} src={edit} className="editIcon" />
              <span className={this.props.appState.appContainerClass === "appContainerSmall" ? "profileHeadingSmall" : "profileHeadingLarge"}>City:</span>
            </div>
            <div className="profileText">{userData.city}</div>
            
            {/* CODE TO OPEN HOODS MODAL */}
            <div className="valign-wrapper">
              <img alt="editIcon" onMouseUp={this.openHoodsModal} onTouchEnd={this.openHoodsModal} src={edit} className="editIcon" />
              <span className={this.props.appState.appContainerClass === "appContainerSmall" ? "profileHeadingSmall" : "profileHeadingLarge"}>Neighborhoods:</span>
            </div>
            <div className="profileText">{userData.neighborhoods.join(", ")}</div>
            
              <div style={{fontSize: "12.5pt", fontWeight: "400", marginTop: "26px", lineHeight: "26px", textAlign: "center"}}>
                
                Click <Link style={{color: "#b93e2b", fontWeight: "700"}} to={`/${this.props.appState.user.uid}`}>here</Link> to view your profile exactly as it appears to the public.
                
              </div>
            
            

            <br/><br/><br/><br/>

          </div>

          <ActivitiesModal appState={this.props.appState} submitActivities={this.submitActivities}/>

          <CityModal appState={this.props.appState} submitCity={this.submitCity}/>

          <HoodsModal unfoldRegion={this.unfoldRegion} countNumberChecked={this.countNumberChecked} appState={this.props.appState} submitHoods={this.submitHoods}/>

          <VerificationReminder />

        </div>
      )
    }
  }
}

export default Profile;