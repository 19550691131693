import React, {Component} from 'react'
import { db } from '../../fbConfig'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import PostModal from './PostModal'
import MessageModal from './MessageModal'
import M from 'materialize-css'
import { functions } from '../../fbConfig'
import EmailNotConfirmed from './EmailNotConfirmed'
import Flag from './Flag'

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posterID: window.location.search.slice(4),
      city: "",
      firstName: "",
      post: "",
      subject: "",
      timeStamp: 0,
      doesNotExist: false,
      resultsLoaded: false,
      cutoffTimeStamp: 0,
      checkingEmail: "",
      otherUserData: ""
    }

    this.getServerTimestamp = functions.httpsCallable('getServerTimestamp');
    this.improvedSendVerificationEmail = functions.httpsCallable('improvedSendVerificationEmail');

  }

  componentDidMount = () => {

    document.title = "Post | Cool Neighbors";

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    this.getServerTimestamp().then(result => {

      var d = new Date(result.data);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var timeStamp = result.data / 1000;
      var secondsSinceMidnight = ((hours * 60) + minutes) * 60;

      var lateNight = false;
      if (hours < 4){
        lateNight = true;
      }

      if (!lateNight){
        var secondsSince4am = secondsSinceMidnight - 14400;
        var timestampAt4am = timeStamp - secondsSince4am;
        var milliTimestampAt4am = timestampAt4am * 1000;

        this.setState({
          cutoffTimeStamp: milliTimestampAt4am
        }, this.setupModals)


      }
      else {  
        var secondsSince4amYesterday = secondsSinceMidnight + 72000;
        var timeStamp4amYesterday = timeStamp - secondsSince4amYesterday;
        var milliTimestamp4amYesterday = timeStamp4amYesterday * 1000;

        this.setState({
          cutoffTimeStamp: milliTimestamp4amYesterday
        }, this.setupModals)

      }

    });

    if (this.state.posterID){
      this.getPost();
    }
  }

  getPost = () => {
    // console.log("Getting post...")
    var postsRef = db.collection('posts');
    var thisPost = postsRef.doc(this.state.posterID);
    thisPost.get().then(doc => {
      if (doc.exists){

        var content = document.getElementById("contentContainer");
        try {
          content.scrollTo(0, 0);
          window.scrollTo(0, 0);
        } catch(e) {
          content.scrollTop = 0;
          window.scrollTop = 0;
        }
        // console.log("Got post.")
        this.setState({
          city: doc.data().city,
          firstName: doc.data().firstName,
          post: doc.data().post,
          postModal: doc.data().post,
          subject: doc.data().subject,
          subjectModal: doc.data().subject,
          timeStamp: doc.data().timeStamp,
          resultsLoaded: true,
          clientTimeStamp: doc.data().clientTimeStamp
        }, this.setupModals)
      } else {
        this.setState({
          doesNotExist: true
        })
      }
    });
  }

  setupModals = () => {
    // console.log("Setting up modals...")
    if (this.modals === undefined){
      var modals = document.querySelectorAll('.modal');
      var modalOptions = {};
      this.modals = M.Modal.init(modals, modalOptions);
    } else if (this.modals.length === 0){
      var modals = document.querySelectorAll('.modal');
      var modalOptions = {};
      this.modals = M.Modal.init(modals, modalOptions);
    }

    if (this.state.cutoffTimeStamp !== 0 && this.state.otherUserData === ""){
      const userRef = db.collection('userData').doc(this.state.posterID);
      userRef.get()
      .then(doc => {
        if (doc.exists){
          console.log("Other user's data found.");

          this.setState({
            otherUserData: doc.data()
          })

        } else {
          console.log("Other user's data not found.")
        }
        
      })
      .catch (err =>{
        console.log(err)
      });
    }
  }


  openPostModal = () => {
    this.modals[0].open();
  }

  openMessageModal = () => {
    this.modals[1].open();
  }

  openFlagModal = () => {
    if (this.props.appState.userData.flagged.includes(this.state.posterID)) {
      alert("You've already flagged this user.")
    }
    else if (this.props.appState.userData.emailVerified) {
      this.modals[3].open();
    } 
    else {
      this.setState({
        checkingEmail: "pending"
      })

      this.props.improvedCheckEmailVerification(
        this.props.appState.userData.uid
      ).then(emailVerification => {
  
        // var emailVerification = result.data.VerificationAttributes[this.props.appState.user.email].VerificationStatus
  
        if (emailVerification === true){
          console.log("Email verified!");
          var emailVerified = {
            emailVerified: true
          } 
          let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
          usersRef.update(emailVerified).then(() => {
            this.setState({
              checkingEmail: ""
            })
            this.modals[3].open();
          });
          
        } else {
          console.log("Email not verified!");
          this.setState({
            checkingEmail: ""
          })
          this.modals[2].open();
        }
      });
    }
  }

  handleTyping = (e) => {
    if (e.target.id === "postTextArea"){
      var postTextArea = e.target.value
      postTextArea = postTextArea.replace(/\ "/g, " “")

      this.setState({
        postModal: postTextArea
      })
    }

    if (e.target.id === "postSubject"){
      var postSubject = e.target.value
      postSubject = postSubject.replace(/\ "/g, " “")


      this.setState({
        subjectModal: postSubject
      })
    }
  }

  sendPost = (e) => {
    var clientTimeStamp = new Date();
    clientTimeStamp = Date.parse(clientTimeStamp.toUTCString());


    var newPost = {
      subject: this.state.subjectModal,
      post: this.state.postModal,
      city: this.state.city,
      clientTimeStamp: clientTimeStamp,
      uid: this.props.appState.user.uid,
      firstName: this.props.appState.userData.firstName
    }

    this.getServerTimestamp().then(result => {
      // console.log("Got timestamp.")

      newPost.timeStamp = result.data;

      let postsRef = db.collection('posts').doc(this.props.appState.user.uid);
      postsRef.set(newPost)
        .then(() => {
          alert("Post updated.")
          this.getPost();
          // console.log("Post updated")
          
        })
        .catch(err => {
          console.log(err);
        });
    });
  }

  deletePost = () => {
    var deletedPost = {
      subject: this.state.subject,
      post: this.state.post,
      city: this.state.city,
      clientTimeStamp: this.state.clientTimeStamp,
      uid: this.props.appState.user.uid,
      firstName: this.props.appState.userData.firstName,
      timeStamp: 0
    }

    let postsRef = db.collection('posts').doc(this.props.appState.user.uid);
    postsRef.set(deletedPost)
      .then(() => {
          // this.getPost();
          this.addPostDataToUser(deletedPost);

        })
      .catch(err => {
        console.log(err);
      });
  }

  addPostDataToUser = (postData) => {

    var deletedPostData = {
      postData: postData
    }

    let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
    usersRef.update(deletedPostData).then(() => {
      alert("Post deleted.")
      this.setState({
        doesNotExist: true
      })
    });
  }

  findCorrespondence = () => {
    var messageBox = document.querySelector('#messageTextArea');
    var message = messageBox.value;
    let receiverId = this.state.posterID;
    let senderId = this.props.appState.user.uid;
    let rAnds = receiverId + " and " + senderId;
    let sAndr = senderId + " and " + receiverId;
    
    var listOfCorrespondences = [];
    var listOfCorrespondenceMoreData = [];

    // console.log(this.props.appState.mailbox)

    this.props.appState.mailbox.forEach(convo => {
      listOfCorrespondences.push(convo[0].correspondence);
      // BELOW MAKES LIST OF 0:correspondence ID, 1:highestTimestamp, 2: receiver of most recent message - used later to figure out if notification should be sent
      listOfCorrespondenceMoreData.push([convo[0].correspondence, convo[1], convo[2]]);
    })

    var firstCheck = listOfCorrespondences.includes(rAnds);
    var secondCheck = listOfCorrespondences.includes(sAndr);

    var extraConvoData;
    if (firstCheck && !secondCheck) {
      console.log("Correspondence found! (First check.)");

        listOfCorrespondenceMoreData.forEach(item => {
          if (item[0] === rAnds){
            extraConvoData = item;
          }
        })

      this.postMessage(message, rAnds, receiverId, senderId, "old", extraConvoData);
    }
    else if (!firstCheck && secondCheck) {
      console.log("Correspondence found! (Second check.)");

      listOfCorrespondenceMoreData.forEach(item => {
        if (item[0] === sAndr){
          extraConvoData = item;
        }
      })

      this.postMessage(message, sAndr, receiverId, senderId, "old", extraConvoData);
    }
    else {
      console.log("Correspondence not found!")
      this.postMessage(message, sAndr, receiverId, senderId, "new")
    }

  }

  postMessage = (message, correspondence, receiverId, senderId, oldNew, extraConvoData) => {
    var messagesRef = db.collection('messages');
    var correspondenceRef = messagesRef.doc(correspondence);

    var timeStamp;
    // // var timeStamp = new Date();
    // // timeStamp = Date.parse(timeStamp.toUTCString());

    this.getServerTimestamp()
      .then(result => {
        timeStamp = result.data;
        var timeStampKey = timeStamp + "-" + senderId;

        if (oldNew === "new"){
          correspondenceRef.set({
            correspondentOne: senderId,
            correspondentTwo: receiverId,
            correspondence: correspondence,
            lastMessageTime: timeStamp,
            data: {
              [timeStampKey]: {
                message,
                test: "test",
                sender: senderId,
                receiver: receiverId,
                senderName: this.props.appState.userData.firstName,
                receiverName: this.state.firstName,
                timeStamp: timeStamp,
                readBySender: true,
                readByReceiver: false
              }
            }
          })
          .then(() => {
            console.log("Message sent!");

            if(this.state.otherUserData.receiveNotifications){
              this.props.sendNotification(this.state.otherUserData.uid, this.props.appState.userData.firstName, message, true);
            }
            
            this.setState({
              messageSent: true,
              correspondenceSent: correspondence.replace(/ /g,"-")
            })
        })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });
        }
        else {
          correspondenceRef.set({
            lastMessageTime: timeStamp,
            data: {
              [timeStampKey]: {
                message,
                test: "test",
                sender: senderId,
                receiver: receiverId,
                senderName: this.props.appState.userData.firstName,
                receiverName: this.state.firstName,
                timeStamp: timeStamp,
                readBySender: true,
                readByReceiver: false
              }
            }
          }, {merge: true})
          .then(() => {
            console.log("Message sent!");

            if(this.state.otherUserData.receiveNotifications){
              // if (this.props.appState.recentlyMessaged.includes(this.state.otherUserData.uid) === false){
              //   this.props.appState.addToRecentlyMessaged(this.state.otherUserData.uid);

              if(extraConvoData[2] === this.state.otherUserData.uid && ((timeStamp - extraConvoData[1]) > 86400000)){
                console.log("A notification was sent because I sent the last message and it has been over 24 hours since that last message.");
                this.props.sendNotification(this.state.otherUserData.uid, this.props.appState.userData.firstName, message);
              }
              else if(extraConvoData[2] !== this.state.otherUserData.uid && ((timeStamp - extraConvoData[1]) > 600000)){
                console.log("A notification was sent because I did not send the last message and it has been 10 min since the other person sent their message.");
                this.props.sendNotification(this.state.otherUserData.uid, this.props.appState.userData.firstName, message);
              } else {
                console.log("No notification was sent.");
              }


              // }
            }

            this.setState({
              messageSent: true,
              correspondenceSent: correspondence.replace(/ /g,"-")
            })
        })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });
        }

        if (this.state.otherUserData.notificationTokens !== undefined && this.state.otherUserData.notificationTokens.length !== 0) {
          console.log("SENDING PUSH!")
          this.props.sendPush({
            name: this.props.appState.userData.firstName,
            tokens: this.state.otherUserData.notificationTokens,
            message: message
          });
        }


      });

  }

  checkEmailVerified = () => {
    if (this.props.appState.userData.flagged.includes(this.state.posterID)) {
      alert("You've flagged this user.")
    }
    else if (this.state.otherUserData.flagged.includes(this.props.appState.userData.uid)){
      alert("This user has flagged you.")
    }
    else if (this.props.appState.userData.emailVerified) {
      this.openMessageModal();
    } else {
      this.setState({
        checkingEmail: "pending"
      }, this.confirmEmail)
    }
  }

  confirmEmail = () => {
    this.props.improvedCheckEmailVerification(
      this.props.appState.userData.uid
    ).then(emailVerification => {
      // var emailVerification = result.data.VerificationAttributes[this.props.appState.user.email].VerificationStatus
      
      if (emailVerification === true){
        console.log("Email verified!");
        var emailVerified = {
          emailVerified: true
        }
        let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
        usersRef.update(emailVerified).then(() => {
          this.openMessageModal();
          this.setState({
            checkingEmail: ""
          })
        });
        
      } else {
        console.log("Email not verified!");
        this.setState({
          checkingEmail: ""
        })
        this.modals[2].open();
      }
    });
  }

  resendVerificationEmail = () => {
    // console.log(this.props.appState.user.email)
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    }).then(result => {
      console.log("Confirmation email sent.");
        alert("Email sent.")
    });
    
  }

  sendFlag = () => {
    var flagNodes = document.querySelectorAll(".flag");
    var date = new Date;
    var flagInfo = {
      [date]: {
        sexual: flagNodes[0].checked,
        hateful: flagNodes[1].checked,
        selling: flagNodes[2].checked,
        other: flagNodes[3].value,
        flagger: this.props.appState.user.uid,
        flaggee: this.state.posterID
      }
    }

    var alreadyFlagged = this.props.appState.userData.flagged;
    alreadyFlagged.push(this.state.posterID);

    let flagsRef = db.collection('flags').doc(this.state.posterID);
    flagsRef.set(flagInfo, {merge: true})
      .then(() => {
        var flagged = {
          flagged: alreadyFlagged
        }
        let usersRef = db.collection('userData').doc(this.props.appState.user.uid);
        usersRef.update(flagged).then(() => {
          alert("User flagged.")
        });
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    if (this.state.posterID.length < 2){
      return <Redirect to="/" />
    } 
    else if (this.state.doesNotExist){
      return <Redirect to="/now" />
    }
    else if (this.state.messageSent){
      return <Redirect push to={`/correspondence?id=${this.state.correspondenceSent}`} />
    }
    else if (this.state.post === ""){
      return <span></span>
    }
    else if (this.state.timeStamp < this.state.cutoffTimeStamp){
      console.log("Post has expired.");
      return <span style={{fontSize: "16pt"}}>This post has expired.</span>
    }
    else if ((this.state.timeStamp > this.state.cutoffTimeStamp) && (this.state.timeStamp !== 0) && (this.state.cutoffTimeStamp !== 0)) {
      var postButtons;
      if (this.props.appState.loggedIn){
        if (this.state.posterID === this.props.appState.user.uid){
          postButtons = <div style={{textAlign: "center"}}>
            <button onMouseUp={this.openPostModal} onTouchEnd={this.openPostModal} className="editDeletePostButton nowPostButton">Edit Post</button><br/>
            <button onMouseUp={this.deletePost} onTouchEnd={this.deletePost} className="editDeletePostButton nowPostButton">Delete Post</button>

          </div>
        } else {
          if (this.state.checkingEmail === ""){
            postButtons = 
            <div style={{textAlign: "center"}}>
              <button onMouseUp={this.checkEmailVerified} onTouchEnd={this.checkEmailVerified} className="navButton sendMessageButton">Send Message</button><br/>
              <button onMouseUp={this.openFlagModal} onTouchEnd={this.openFlagModal} className="flagButton">Flag</button>
            </div>
          } else {
            postButtons = 
            <div style={{textAlign: "center"}}>
              <button disabled className="navButton sendMessageButton">Please wait.</button><br/>
              <button disabled className="flagButton">Flag</button>
            </div>
          }

        }
      } else {
        postButtons = 
          <div style={{textAlign: "center"}}>
            <button disabled className="navButton sendMessageButton">Send Message</button><br/>
            <button disabled className="flagButton">Flag</button>
          </div>
      }

      // console.log(this.props)

      var datePosted = new Date(this.state.timeStamp);
      var timePosted = moment(datePosted).calendar();
      // console.log(typeof timePosted)

      return(
        <div style={{maxWidth: "800px"}}>
          <div className="postHeading">{this.state.subject.replace(/ “/g, " \"")}</div>
          <div className="postContent">{this.state.post}</div><br/>
          <div className="postAuthor">Posted by
            <Link style={{fontWeight: "600", color: "#b93e2b"}} to={`/${this.state.posterID}`}> {this.state.firstName}</Link>
          </div>
          <div className="postDate">
            {timePosted} in {this.state.city}
          </div>
          {postButtons}
          <br/><br/><br/>

          <PostModal postSubject={this.state.subjectModal} postText={this.state.postModal} handleTyping={this.handleTyping} sendPost={this.sendPost}/>

          <MessageModal findCorrespondence={this.findCorrespondence}/>

          <EmailNotConfirmed resendVerificationEmail={this.resendVerificationEmail} />

          <Flag sendFlag={this.sendFlag} />
        </div>
      )
    } else {
      return <span></span>
    }

    
  }
}

export default Post;