import React, {Component} from 'react'
import close from "../../images/close.png"


class DesktopImage extends Component {
  constructor(props) {
    super(props);

    this.picContainer = React.createRef();
    this.closeIcon = React.createRef();

  }

  componentDidMount = () => {

  }

  componentDidUpdate = () => {
    // console.log("update")
    this.setClosePosition();
  }

  setClosePosition = () => {

    var picContainer = this.picContainer.current;
    var closeIcon = this.closeIcon.current;
    
    if (closeIcon !== null && picContainer !== null){
      picContainer.classList.remove("hide");
      closeIcon.style.top = (picContainer.clientHeight - 28).toString() + "px";
      closeIcon.style.left = (picContainer.clientWidth - 33).toString() + "px";
      setTimeout(() => {closeIcon.classList.remove("invisible")}, 1500);
      
    }
  }


  render() {

    var userData = this.props.appState.userData;
    var content;

    if (this.props.appState.appContainerClass === "appContainerSmall"){
      content = <span></span>
    }
    else if (this.props.profileState.processingImage === true){
      content = <span className="pleaseWait">Please wait.</span>
    }

    else if (userData.picURL === "blankPic.png"){
      content = 
      <div>
        <label htmlFor="uploadPic">
          <img alt="blankPic" className="blankPic" src="blankPic.png"></img>

          <input onChange={this.props.readPic} id="uploadPic" className="hide" type="file" name="pic" accept="image/*"/>
        </label>
      </div>
    }

    else {
      content = 
      <div>
        <img alt="closeIcon" ref={this.closeIcon} onMouseUp={this.props.deletePic} onTouchEnd={this.props.deletePic} src={close} className="closeIcon invisible" />
        <img alt="profilePic" onLoad={this.setClosePosition} ref={this.picContainer} src={userData.picURL} className="desktopProfilePic materialboxed hide" />

      </div>
    }

    return(
      <div>
        {content}
      </div>
    )
  }
}

export default DesktopImage;