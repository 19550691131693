import React, { Component } from 'react'



class ChangeEmailModal extends Component {
  constructor(props) {
    super(props);



  }


  componentDidMount = () => {


  }


  componentDidUpdate = () => {


  }


  render() {
    return (
      <div id="changeEmailModal" className="modal modal-fixed-footer">
        <div style={{ fontSize: "14pt", textAlign: "center" }} className="modal-content noscrollX">

          <div style={{ marginBottom: "10px" }}>Type your <span style={{ fontWeight: "700" }}>current</span> email address and password below.</div>

          <input value={this.props.accountPageState.currentEmail} onChange={this.props.handleChange} className="browser-default changeEmailPassword" type="email" name="currentEmail" placeholder="Current Email" /><br />

          <input value={this.props.accountPageState.currentPassword} onChange={this.props.handleChange} className="browser-default changeEmailPassword" type="password" name="currentPassword" placeholder="Password" />

          <div style={{ marginBottom: "10px", marginTop: "19px" }}>Next, type your <span style={{ fontWeight: "700" }}>new</span> email address.</div>

          <input value={this.props.accountPageState.newEmail} onChange={this.props.handleChange} className="browser-default changeEmailPassword" type="email" name="newEmail" placeholder="New Email" /><br />


        </div>
        <div className="modal-footer">
          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
          <a onMouseUp={this.props.changeEmail} onTouchEnd={this.props.changeEmail} className="modal-close waves-effect waves-red btn red darken-3">SUBMIT</a>
        </div>
      </div>
    )
  }
}

export default ChangeEmailModal;