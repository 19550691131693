import React, {Component} from 'react'
import close from "../../images/close.png"


class MobileImage extends Component {
  constructor(props) {
    super(props);

    this.picContainer = React.createRef();
    this.closeIcon = React.createRef();
  }

  componentDidMount = () => {

  }

  componentDidUpdate = () => {
    // this.setCloseIcon();
    this.setClosePosition();
  }

  setClosePosition = () => {
    var picContainer = this.picContainer.current;
    var closeIcon = this.closeIcon.current;
    
    if (closeIcon !== null && picContainer !== null){
      picContainer.classList.remove("hide");
      closeIcon.style.top = (picContainer.clientHeight - 21).toString() + "px";
      closeIcon.style.left = (picContainer.clientWidth - 30).toString() + "px";
      setTimeout(() => {closeIcon.classList.remove("invisible")}, 1500);
    }
}

  render() {
    var userData = this.props.appState.userData;
    // console.log(userData.picURL)
    // var showLoadingPic = userData.picURL ? "show" : "";
    // console.log(hideLoadingPic);
    var content;

    if (this.props.appState.appContainerClass !== "appContainerSmall"){
      content = <span></span>
    }
    else if (this.props.profileState.processingImage === true){
      content = <span className="pleaseWait">Please wait.</span>
    }

    else if (userData.picURL === "blankPic.png"){
      content = 
      <div>
        <label htmlFor="uploadPic">
          <img alt="blankPic" className="blankPicSmall" src="blankPic.png"></img>

          <input onChange={this.props.readPic} id="uploadPic" className="hide" type="file" name="pic" accept="image/*"/>
        </label>
      </div>
    }

    else {
      content = 
      <div style={{display: "inline-block", maxWidth: "300px", maxHeight: "300px"}}>
        <img alt="closeIcon" ref={this.closeIcon} onMouseUp={this.props.deletePic} onTouchEnd={this.props.deletePic} src={close} className={`closeIcon invisible`} />

        <img alt="mobileProfilePic" onLoad={this.setClosePosition} ref={this.picContainer} src={userData.picURL} className={`mobileProfilePic materialboxed hide`} />

      </div>
    }

    return(
      <div>
        {content}
      </div>
    )
  }
}

export default MobileImage;