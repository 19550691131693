import React, {Component} from 'react'
import mail from "../../images/mail.png"
import newMail from "../../images/newMail.png"
import { Link } from 'react-router-dom'

class MessageRow extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
    // console.log(this.props.messageInfo.correspondence)
  }

  render() {
    var { currentUserId } = this.props;
    var correspondenceData = this.props.messageInfo.data;
    var listOfMessageKeys = Object.keys(this.props.messageInfo.data);
    // var firstMessage = correspondenceData[listOfMessageKeys[0]];
    var lastMessage = correspondenceData[listOfMessageKeys[listOfMessageKeys.length-1]];
    
    
    if (lastMessage.sender === currentUserId){
      var nameForLabel = lastMessage.receiverName;
    } else {
      var nameForLabel = lastMessage.senderName;
    }

    var correspondence = this.props.messageInfo.correspondence.replace(/ /g,"-");

    var icon = ""
    if (this.props.unreadMessages === true){
      icon = newMail;
    } else {
      icon = mail;
    }

    if (this.props.appState.appContainerClass !== "appContainerSmall"){
      return(
        <Link to={`/correspondence?id=${correspondence}`} style={{ color: 'black' }}>
          <div className="messageRow valign-wrapper">
            <img style={{width: "26px"}} src={icon} alt=""/>
            <span className="messageLabel">{nameForLabel}</span>
          </div>
        </Link>
        )
    } else {
      return (
        <Link to={`/correspondence?id=${correspondence}`} style={{ color: 'black' }}>
          <div className="messageRowSmall valign-wrapper">
            <img style={{width: "26px"}} src={icon} alt=""/>
            <span className="messageLabel">{nameForLabel}</span>
          </div>
        </Link>
      )
    }


  }
}

export default MessageRow;