import React, {Component} from 'react'
import { neighborhoods } from '../../cityData/hoods'
import unfoldArrow from '../../images/unfoldArrow.png'

class HoodsModal extends Component {
  constructor(props) {
    super(props);
    


  }


  render() {
      if (this.props.appState.userData.neighborhoods === undefined){
        var modalContent = <div></div>
      }
      else {
        var checkedHoods = this.props.appState.userData.neighborhoods;

        var cityData = neighborhoods[this.props.appState.userData.city]
        var regions = Object.keys(cityData);
        var modalContent;
        
        if (this.props.appState.appContainerClass !== "appContainerSmall"){
          modalContent = 
            <div className="row">
              <div className="col l6 m6">
                {regions.map(region => {
                  if (regions.indexOf(region) % 2 === 0){
                    var regionNoSpace = region.replace(/ /g,'');
                    return(
                      <div key={region}>
                        <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>
                        {cityData[region].map(hood => {
                          var check = "";
                          checkedHoods.includes(hood) ? check = "checked" : check = "";
                          
                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                defaultChecked={check}
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox" 
                                onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                          )
                        })}
                      </div>
                    )
                  }
                })}
              </div>

              <div className="col l6 m6">
                {regions.map(region => {
                  if (regions.indexOf(region) % 2 === 1){
                    var regionNoSpace = region.replace(/ /g,'');
                    return(
                      <div key={region}>
                        <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>

                        {cityData[region].map(hood => {
                          var check = "";
                          checkedHoods.includes(hood) ? check = "checked" : check = "";

                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input 
                              defaultChecked={check}
                              value={hood} 
                              id={hood} 
                              className="hood" 
                              type="checkbox" 
                              onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                          )
                        })}

                      </div>
                    )
                  }
                })}
              </div>
            </div>
        } else {

          modalContent = 
            <div className="row">
              <div className="col l6 m6">
                {regions.map(region => {
                  {/* if (regions.indexOf(region) % 2 === 0){ */}
                    var regionNoSpace = region.replace(/ /g,'');
                    return(
                      <div key={region}>
                        <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>
                        {cityData[region].map(hood => {
                          var check = "";
                          checkedHoods.includes(hood) ? check = "checked" : check = "";
                          
                          return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                defaultChecked={check}
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox" 
                                onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                          )
                        })}
                      </div>
                    )
                  {/* } */}
                })}
              </div>
            </div>



        }
      }




    return(
      <div id="hoodsModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">Neighborhoods</h4>
          {modalContent}

   
        </div>
        <div className="modal-footer">
          <a onMouseUp={this.props.submitHoods} onTouchEnd={this.props.submitHoods} className="modal-close waves-effect waves-red btn-flat">DONE</a>
        </div>
      </div>
    )
  }
}

export default HoodsModal;