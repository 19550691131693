import React, {Component} from 'react'

class EmailNotConfirmed extends Component {
  constructor(props) {
    super(props);

  }


  componentDidMount = () => {


  }


  componentDidUpdate = () => {


  }

  

  render() {
    return(
      <div id="notConfirmedModal" className="modal modal-fixed-footer">
        <div style={{fontSize: "14pt", textAlign: "center", marginTop: "12px"}} className="modal-content noscrollX">
        
        Please confirm your email address before sending messages.  Check your inbox for a verification email from Cool Neighors.
        <br/><br/>
        <a style={{backgroundColor: "#b93e2b", color: "white", padding: "10px", borderRadius: "5px", fontSize: "10pt", boxShadow: "2px 2px 5px rgb(179, 179, 179)"}} onMouseUp={this.props.resendVerificationEmail} onTouchEnd={this.props.resendVerificationEmail} className="modal-close waves-effect waves-red">RESEND VERIFICATION EMAIL </a>
        
        </div>
        <div className="modal-footer">
        
          <a className="modal-close waves-effect waves-red btn-flat">OK</a>
        </div>
      </div>
    )
  }
}

export default EmailNotConfirmed;