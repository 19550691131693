export const activities = [
"Activism",
"Arts / Crafts / Sewing",
"Bars / Drinking",
"Biking", 
"Birding", 
"Board games / Cards", 
"Camping", 
"Cars / Motorcycles", 
"Cooking / Baking / Grilling", 
"Creating digital art", 
"Creative writing", 
"Dancing", 
"Dinner parties", 
"Entrepreneurship / Business partnerships", 
"Environmentalism", 
"Gardening", 
"Faith / Spirituality", 
"Going to concerts", 
"Hiking", 
"Horseback riding", 
"Hunting / Fishing", 
"Ice skating / Rollerblading", 
"Karaoke", 
"Kayaking / Canoeing", 
"Learning / Speaking languages", 
"Listening to music", 
"LGBTQ", 
"Making movies / Making documentaries", 
"Making music / Jamming / Singing", 
"Martial arts", 
"Math and science", 
"Museums", 
"Musicals / Drama / Broadway", 
"Painting / Drawing / Sculpting", 
"Parenting", 
"Partying", 
"Pets", 
"Photography", 
"Playing sports", 
"Politics", 
"Pop culture", 
"Programming / Coding", 
"Reading / Books", 
"Restaurants", 
"Running / jogging", 
"Scuba diving / Snorkeling", 
"Skateboarding", 
"Skiing / Snowboarding", 
"Stand-up comedy", 
"Surfing", 
"Swimming", 
"The beach", 
"The gym", 
"Tinkering / Electronics / Woodworking", 
"Traveling", 
"Trivia", 
"Video games", 
"Volunteering", 
"Walking / Wandering", 
"Watching movies", 
"Watching sports", 
"Yoga", 
"420", 
"Other"
]