import React, {Component} from 'react'
import { activities } from '../cityData/activities'
import unfoldArrow from '../images/unfoldArrow.png'

class SignupModal extends Component {

  componentDidUpdate = () => {
    // console.log("modal update")
  }

  render() {
    // console.log("OK")
    //console.log(this.state)
    // console.log(this.props.signupState.hoods)
    var modalContent;
    var heading = this.props.signupState.modalData.heading;
    var data = this.props.signupState.modalData.data;
    // console.log(data)

    if (heading === "Your City"){
      modalContent = data.map(city => {
        return(
          <p key={city}>
          <label htmlFor={city}>
            <input id={city} type="radio" name="city" className="city" value={city} />
            <span>{city}</span>
          </label>
          </p>
        )
      })
    }

    if (heading === "Neighborhoods"){
      var checkedHoods = this.props.signupState.hoods;
      var regions = Object.keys(data);
      
      if (this.props.appState.appContainerClass !== "appContainerSmall"){
        modalContent = 
          <div className="row">
            <div className="col l6 m6">
              {regions.map(region => {
                if (regions.indexOf(region) % 2 === 0){
                  var regionNoSpace = region.replace(/ /g,'');
                  return(
                    <div key={region}>
                      <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>
                      
                      {data[region].map(hood => {
                        var check = "";
                        checkedHoods.includes(hood) ? check = "checked" : check = "";
                        
                        return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                defaultChecked={check}
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox"
                                onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                        )
                      })}
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>

            <div className="col l6 m6">
              {regions.map(region => {
                if (regions.indexOf(region) % 2 === 1){
                  var regionNoSpace = region.replace(/ /g,'');
                  return(
                    <div key={region}>
                      <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>

                      {data[region].map(hood => {
                        var check = "";
                        checkedHoods.includes(hood) ? check = "checked" : check = "";

                        return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input 
                              defaultChecked={check}
                              value={hood} 
                              id={hood} 
                              className="hood" 
                              type="checkbox"
                              onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                        )
                      })}

                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>
      } else {
        modalContent = 
          <div className="row">
            <div className="col l6 m6">
              {regions.map(region => {
                {/* if (regions.indexOf(region) % 2 === 0){ */}
                  var regionNoSpace = region.replace(/ /g,'');
                  return(
                    <div key={region}>
                      <h5 id={regionNoSpace} onClick={this.props.unfoldRegion} className="regionHeader folded" style={{fontWeight: 500}}>{region} <img alt={regionNoSpace} className="folded" style={{width: "10px"}} src={unfoldArrow} /></h5>
                      
                      {data[region].map(hood => {
                        var check = "";
                        checkedHoods.includes(hood) ? check = "checked" : check = "";
                        
                        return(
                            <label className={regionNoSpace + " hide"} htmlFor={hood} key={hood}>
                              <input
                                defaultChecked={check}
                                id={hood}
                                value={hood} 
                                className="hood" 
                                type="checkbox"
                                onMouseUp={this.props.countNumberChecked} onTouchEnd={this.props.countNumberChecked} />
                              <span>{hood}</span><br/>
                            </label>
                        )
                      })}
                    </div>
                  )
                {/* } else {
                  return null
                } */}
              })}
            </div>
          </div>
      }


      }
      if (heading === "Activities"){
        var checkedActivs = this.props.signupState.activities;
        var halfwayPoint = Math.floor(activities.length/2) - 1;
        modalContent = 
          <div className="row">
            <div className="col l6 m6">
            {data.map(activity => {
              if (activities.indexOf(activity) <= halfwayPoint){

                var check = "";
                checkedActivs.includes(activity) ? check = "checked" : check = "";

                return(
                  <label key={activity}>
                    <input 
                    defaultChecked={check}
                    value={activity} 
                    id={activity} 
                    className="activity" 
                    type="checkbox" />
                    <span>{activity}</span><br/>
                  </label>
                )
              } else {
                return null
              }
            })}


            </div>
            <div className="col l6 m6">
              {data.map(activity => {
                if (activities.indexOf(activity) > halfwayPoint){

                  var check = "";
                  checkedActivs.includes(activity) ? check = "checked" : check = "";

                  return(
                    <label key={activity}>
                      <input 
                      defaultChecked={check}
                      value={activity} 
                      id={activity} 
                      className="activity" 
                      type="checkbox" />
                      <span>{activity}</span><br/>
                    </label>
                  )
                } else {
                return null
              }
              })}

            </div>
          </div>
      }




    return(
      <div id="signupModal" className="modal modal-fixed-footer">
        <div id="modalContent" className="modal-content noscrollX">
          <h4 id="modalHeading" className="modalHeading">{this.props.signupState.modalData.heading}</h4>
          {modalContent}

   
        </div>
        <div className="modal-footer">
          <a href="#!" onClick={this.props.signupState.modalData.doneFunction} className="modal-close waves-effect waves-red btn-flat">DONE</a>
        </div>
      </div>
    )
  }
}

export default SignupModal;