import React, { Component } from 'react'
import edit from "../../images/editIcon.png"
import check from "../../images/check.svg"


class About extends Component {
  constructor(props) {
    super(props);

  }

  render(){
    if (this.props.appState.userData.about === undefined){
      return <div></div>
    } else {
      return (
        <div>
        <div className="valign-wrapper">
          {/* ABOUT EDIT ICON */}
            <img 
            onMouseUp={this.props.toggleEditAbout} onTouchEnd={this.props.toggleEditAbout}
            src={edit} 
            className={`
            editIcon
            ${this.props.editingAbout ? "hide" : ""}
          `} />

          {/* ABOUT DISPLAY */}
          <span 

          className={`
          ${this.props.appState.appContainerClass == "appContainerSmall" ? "profileHeadingSmall" : "profileHeadingLarge"}
          
          ${this.props.editingAbout ? "hide" : ""}
          `}
          >About:</span></div>

          <div style={{whiteSpace: "pre-wrap"}} className={`
          profileText
          ${this.props.editingAbout ? "hide" : ""}
          
          `}>{this.props.userData.about}</div>

        
          {/* ABOUT INPUT */}
          <div className={this.props.editingAbout ? "" : "hide"}>
          <textarea className={`
            browser-default 
            editAboutInput
          `} 
          placeholder="Say a little about yourself!" 
          maxLength="1700"
          defaultValue={this.props.userData.about}
          onChange={this.props.checkAbout}
          />

          <img className="checkIconAbout" src={check} onMouseUp={this.props.submitAbout} onTouchEnd={this.props.submitAbout} />
          <div style={{marginTop: "-15px", marginBottom: "17px", fontSize: "12pt", fontWeight: "500"}}>Words: {this.props.wordCount}</div>
          </div>

        </div>
        )
      }
    }

}


export default About;