import React, { Component } from "react";
import PostModal from "./PostModal";
import CityModal from "./CityModal";
import M from "materialize-css";
import { functions } from "../../fbConfig";
import { db } from "../../fbConfig";
import { Link } from "react-router-dom";
import EmailNotConfirmed from "./EmailNotConfirmed";

class Now extends Component {
  constructor(props) {
    super(props);

    this.state = {
      city: localStorage.getItem("nowCity"),
      postText: "",
      postSubject: "",
      usersLastPostTime: 0,
      listOfPosts: [],
      resultsLoaded: false,
      userPostSearchComplete: false,
      cutoffTimeStamp: 0,
      checkingEmail: "",
    };
    
    this.getServerTimestamp = functions.httpsCallable("getServerTimestamp");
    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
      
    // this.checkAws = functions.httpsCallable('checkAws');
  }

  componentDidMount = () => {
    document.title = "Happening Now | Cool Neighbors";

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch (e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    var modals = document.querySelectorAll(".modal");
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);

    this.getPosts();

    if (this.props.appState.loggedIn) {
      this.getUsersPost();
    }

    if (this.state.city === "New York") {
      this.setState({
        city: "New York City / NY Metropolitan Area",
      });
    }
  };

  getUsersPost = () => {
    let postsRef = db.collection("posts").doc(this.props.appState.user.uid);
    postsRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          // console.log(data)
          this.setState({
            postText: data.post,
            postSubject: data.subject,
            usersLastPostTime: data.timeStamp,
            userPostSearchComplete: true,
          });
        } else {
          this.setState({
            userPostSearchComplete: true,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  componentDidUpdate = () => {
    if (this.state.city === "New York") {
      this.setState({
        city: "New York City / NY Metropolitan Area",
      });
    }
  };

  openPostModal = () => {
    this.modals[0].open();
  };

  openCityModal = () => {
    this.modals[1].open();
  };

  handleTyping = (e) => {
    if (e.target.id === "postTextArea") {
      var postText = e.target.value;
      postText = postText.replace(/\ "/g, " “");

      this.setState({
        postText: postText,
      });
    }
    if (e.target.id === "postSubject") {
      var subjectText = e.target.value;
      subjectText = subjectText.replace(/\ "/g, " “");

      this.setState({
        postSubject: subjectText,
      });
    }
  };

  submitCity = () => {
    var cities = document.querySelectorAll(".city");
    var selectedCity;
    cities.forEach((city) => {
      if (city.checked) {
        selectedCity = city.value;
      }
    });

    localStorage.setItem("nowCity", selectedCity);

    this.setState(
      {
        city: selectedCity,
        resultsLoaded: false,
      },
      this.getPosts
    );
  };

  sendPost = () => {
    var clientTimeStamp = new Date();
    clientTimeStamp = Date.parse(clientTimeStamp.toUTCString());
    // console.log(clientTimeStamp);

    var newPost = {
      subject: this.state.postSubject,
      post: this.state.postText,
      city: this.state.city,
      clientTimeStamp: clientTimeStamp,
      uid: this.props.appState.user.uid,
      firstName: this.props.appState.userData.firstName,
    };

    // console.log(newPost)

    this.getServerTimestamp()
      .then((result) => {
        // Read result of the Cloud Function.
        // var sanitizedMessage = result.data.text;
        newPost.timeStamp = result.data;
        // console.log(newPost)
        let postsRef = db.collection("posts").doc(this.props.appState.user.uid);
        postsRef.set(newPost).then(() => {
          this.getUsersPost();
          this.getPosts();
          this.addPostDataToUser(newPost);
          alert("Post successful!");
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // this.setState({
    //   postText: "",
    //   postSubject: ""
    // }, this.getPosts)
  };

  addPostDataToUser = (postData) => {
    // console.log(postData)
    var newPostData = {
      postData: postData,
    };

    let usersRef = db.collection("userData").doc(this.props.appState.user.uid);
    usersRef.update(newPostData);
  };

  checkCity = (doc) => {
    if (doc.city === this.state.city) {
      return doc;
    }
  };

  getPosts = () => {
    // if it's late night, then i need all of today before 4 and all of yesterday after 4

    // if it's not late night, then I need all of today after 4

    this.getServerTimestamp().then((result) => {
      var d = new Date(result.data);

      // var d = new Date;
      // console.log(d)
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var timeStamp = result.data / 1000;
      var secondsSinceMidnight = (hours * 60 + minutes) * 60;
      // var timeStampAtMidnight = timeStamp - secondsSinceMidnight;

      var lateNight = false;
      if (hours < 4) {
        lateNight = true;
      }
      // console.log(milliTimestampAt4am);

      var listOfPosts = [];

      let postsRef = db.collection("posts");
      // var query = postsRef.where("city", "==", this.state.city);

      var content = document.getElementById("contentContainer");

      if (!lateNight) {
        var secondsSince4am = secondsSinceMidnight - 14400;
        var timestampAt4am = timeStamp - secondsSince4am;
        var milliTimestampAt4am = timestampAt4am * 1000;

        var daytimeQuery = postsRef.where(
          "timeStamp",
          ">=",
          milliTimestampAt4am
        );

        daytimeQuery
          .get()
          .then((querySnapshot) => {
            try {
              content.scrollTo(0, 0);
              window.scrollTo(0, 0);
            } catch (e) {
              content.scrollTop = 0;
              window.scrollTop = 0;
            }

            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());

              // console.log(doc.data())

              listOfPosts.push(doc.data());
            });

            listOfPosts = listOfPosts.filter(this.checkCity);

            var date = new Date();
            var day = date.getDay();

            var dayAsPercent = day / 7;
            var startingPointInResultsArray = Math.floor(
              listOfPosts.length * dayAsPercent
            );

            var secondHalf = listOfPosts.slice(
              startingPointInResultsArray,
              listOfPosts.length
            );
            var firstHalf = listOfPosts.slice(0, startingPointInResultsArray);

            listOfPosts = secondHalf.concat(firstHalf);

            this.setState(
              {
                listOfPosts: listOfPosts,
                resultsLoaded: true,
                cutoffTimeStamp: milliTimestampAt4am,
              },
              () => {
                console.log(this.state.listOfPosts);
              }
            );
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        var secondsSince4amYesterday = secondsSinceMidnight + 72000;
        var timeStamp4amYesterday = timeStamp - secondsSince4amYesterday;

        var milliTimestamp4amYesterday = timeStamp4amYesterday * 1000;

        var lateNightQuery = postsRef.where(
          "timeStamp",
          ">=",
          milliTimestamp4amYesterday
        );
        lateNightQuery
          .get()
          .then((querySnapshot) => {
            try {
              content.scrollTo(0, 0);
              window.scrollTo(0, 0);
            } catch (e) {
              content.scrollTop = 0;
              window.scrollTop = 0;
            }

            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              listOfPosts.push(doc.data());
            });

            listOfPosts = listOfPosts.filter(this.checkCity);

            this.setState(
              {
                listOfPosts: listOfPosts,
                resultsLoaded: true,
                cutoffTimeStamp: milliTimestamp4amYesterday,
              },
              () => {
                console.log(this.state.listOfPosts);
              }
            );
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    });
  };

  checkEmailVerified = () => {
    if (this.props.appState.userData.emailVerified) {
      this.openPostModal();
    } else {
      this.setState(
        {
          checkingEmail: "pending",
        },
        this.confirmEmail
      );
    }
  };

  confirmEmail = () => {
    this.props
      .improvedCheckEmailVerification(this.props.appState.userData.uid)
      .then((emailVerification) => {
        // var emailVerification =
        //   result.data.VerificationAttributes[this.props.appState.user.email]
        //     .VerificationStatus;

        if (emailVerification === true) {
          console.log("Email verified!");
          var emailVerified = {
            emailVerified: true,
          };
          let usersRef = db
            .collection("userData")
            .doc(this.props.appState.user.uid);
          usersRef.update(emailVerified).then(() => {
            this.openPostModal();
            this.setState({
              checkingEmail: "",
            });
          });
        } else {
          console.log("Email not verified!");
          this.setState({
            checkingEmail: "",
          });
          this.modals[2].open();
        }
      });
  };

  resendVerificationEmail = () => {
    // console.log(this.props.appState.user.email)
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    }).then((result) => {
      console.log("Confirmation email sent.");
      alert("Email sent.");
    });
  };

  render() {
    let textClass;
    if (this.props.appState.appContainerClass === "appContainerSmall") {
      textClass = "largerText nowPara";
    } else {
      textClass = "flow-text nowPara";
    }

    var allPostsContent;
    if (this.state.resultsLoaded === false) {
      allPostsContent = (
        <div
          style={{
            fontSize: "14pt",
            maxWidth: "800px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Please wait...
        </div>
      );
    } else if (this.state.listOfPosts.length < 1) {
      allPostsContent = (
        <div
          style={{
            fontSize: "14pt",
            fontWeight: "500",
            textAlign: "center",
            maxWidth: "800px",
          }}
        >
          No results found.
        </div>
      );
    } else {
      allPostsContent = this.state.listOfPosts.map((post) => {
        return (
          <Link
            key={post.uid}
            to={`/post?id=${post.uid}`}
            style={{ color: "black" }}
          >
            <div className="postRow">{post.subject}</div>
          </Link>
        );
      });
    }

    var createPostButton;
    // console.log(this.state.usersLastPostTime);
    // console.log(this.state.cutoffTimeStamp)
    if (this.props.appState.loggedIn) {
      if (!this.state.userPostSearchComplete || !this.state.resultsLoaded) {
        createPostButton = <span></span>;
      } else if (this.state.usersLastPostTime > this.state.cutoffTimeStamp) {
        createPostButton = (
          <Link
            to={`/post?id=${this.props.appState.user.uid}`}
            style={{ color: "black" }}
          >
            <button className="nowPostButton">View / Edit Post</button>
          </Link>
        );
      } else if (this.state.checkingEmail === "pending") {
        createPostButton = (
          <button disabled className="nowPostButton">
            Create a Post
          </button>
        );
      } else {
        createPostButton = (
          <button
            onMouseUp={this.checkEmailVerified}
            onTouchEnd={this.checkEmailVerified}
            className="nowPostButton"
          >
            Create a Post
          </button>
        );
      }
    } else {
      createPostButton = (
        <button disabled className="nowPostButton">
          Create a Post
        </button>
      );
    }

    return (
      <div>
        <div className="nowHeading">Happening Now!</div>

        <p className={textClass}>
          Welcome to our “Happening Now” page.&ensp;Here you'll discover what
          people in your city are doing right now!
        </p>

        <p className={textClass}>
          You're only allowed one active post at a time.&ensp;All posts
          disappear at 4am.
        </p>

        <div className="nowSelectedCity">
          <div style={{ float: "left", height: "75px", marginTop: "10px" }}>
            <button
              onMouseUp={this.openCityModal}
              onTouchEnd={this.openCityModal}
              className="nowSelectButton"
            >
              Select
            </button>
          </div>

          <div
            style={{
              fontSize: "13.5pt",
              fontWeight: "700",
              float: "left",
              height: "75px",
              marginTop: "11px",
            }}
          >
            <div>City:&ensp;</div>
          </div>

          <div
            style={{
              fontSize: "13.5pt",
              fontWeight: "400",
              height: "65px",
              paddingTop: "12px",
              overflow: "hidden",
              textOverflow: "hidden",
            }}
          >
            {this.state.city}
          </div>
        </div>
        <br />

        <div style={{ maxWidth: "800px" }} className="divider"></div>
        <br />

        <div style={{ maxWidth: "800px", textAlign: "center" }}>
          {createPostButton}

          <br />
          <br />
        </div>

        {allPostsContent}
        <br />
        <br />
        <br />

        <PostModal
          postSubject={this.state.postSubject}
          postText={this.state.postText}
          handleTyping={this.handleTyping}
          sendPost={this.sendPost}
        />

        <CityModal submitCity={this.submitCity} />

        <EmailNotConfirmed
          resendVerificationEmail={this.resendVerificationEmail}
        />
      </div>
    );
  }
}

export default Now;
