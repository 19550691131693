import React, { Component } from "react";
import { db, functions, auth } from "../fbConfig";
import M from "materialize-css";
import ForgotPasswordModal from "./ForgotPasswordModal.js";
import firebase from "firebase/app";

class ImprovedEmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeMatch: "pending",
      urlCode: 0,
      dbCode: 0,
      dbUpdated: false,
      uid: "",
      formLoaded: false,
      signingIn: false,
      successfulSignin: false,
    };

    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
  }

  componentDidMount = () => {
    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch (e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Confirmed | Cool Neighbors";

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const urlCode = parseInt(urlParams.get("code"));
    const uid = urlParams.get("uid");
    this.setState(
      {
        urlCode: urlCode,
        uid: uid,
      },
      this.getCodeFromDatabase
    );
  };

  getCodeFromDatabase = () => {
    const userRef = db.collection("confirmedEmails").doc(this.state.uid);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var dbCode = doc.data().code;
          console.log(dbCode);
          this.setState(
            {
              dbCode: dbCode,
            },
            this.checkForMatch
          );
        } else {
          this.setState({
            codeMatch: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkForMatch = () => {
    if (this.state.dbCode === this.state.urlCode) {
      console.log("Match!");
      this.updateUser();
    } else {
      console.log("No match!");
      this.setState({
        codeMatch: false,
      });
    }
  };

  updateUser = () => {
    var confirm = {
      confirmed: true,
    };
    let usersRef = db.collection("confirmedEmails").doc(this.state.uid);
    usersRef
      .update(confirm)
      .then(() => {
        console.log("confirmed!");
        this.setState({
          codeMatch: true,
        });
      })
      .catch((err) => console.log(err));

      
  };

  componentDidUpdate = () => {
    if (this.state.codeMatch === false && !this.state.formLoaded) {
      var pwInput = document.querySelector("#pw");
      pwInput.addEventListener("keyup", (event) => {
        if (event.keyCode === 13) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          // document.getElementById("submitButton").click();
          this.getInputs();
        }
      });
      this.setState(
        {
          formLoaded: true,
        },
        this.setupModals
      );
    }

    if (
      Object.keys(this.props.appState.userData).length > 0 &&
      this.state.successfulSignin
    ) {
      this.sendEmail();
      this.setState({
        successfulSignin: false,
      });
    }
  };

  setupModals = () => {
    var modals = document.querySelectorAll(".modal");
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);
    // console.log(modals);
  };

  openForgotPwModal = () => {
    this.modals[0].open();
  };

  signIn = () => {
    this.setState({
      signingIn: true,
    });

    let inputs = document.querySelectorAll("input, textarea");
    let formValues = {};
    inputs.forEach((input) => (formValues[input.name] = input.value));

    const email = formValues.email;
    const password = formValues.password;

    // console.log(email, password)

    auth
      .signInWithEmailAndPassword(email, password)
      .then((cred) => {
        console.log("Got user data...");
        this.setState({
          successfulSignin: true,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        this.setState({
          signingIn: false,
        });
      });
  };

  sendEmail = () => {
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    })
      .then((result) => {
        alert("Confirmation email sent.");
        this.setState({
          signingIn: false,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (this.state.codeMatch === "pending") {
      return (
        <div
          style={{
            fontSize: "16pt",
            fontWeight: "400",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <br />
          <span style={{ fontWeight: "700" }}>Loading...</span>
          <br />
        </div>
      );
    } else if (this.state.codeMatch === false) {
      return (
        <div
          style={{
            fontSize: "16pt",
            fontWeight: "400",
            marginTop: "10px",
          }}
        >
          <br />
          <span style={{ fontWeight: "700" }}>
            Sorry, we could not confirm your email address.
          </span>
          <br />
          <br />
          If you're still unable to communicate with other users, you can resend
          a verification message to your email address by using the form below:
          <br />
          <br />
          <div style={{ textAlign: "left" }}>
            <input
              className="browser-default signinInput"
              type="email"
              name="email"
              placeholder="Email"
              id="email"
            />
            <br />

            <input
              id="pw"
              className="browser-default signinInput"
              type="password"
              name="password"
              placeholder="Password"
            />
            <br />

            <div
              style={{
                marginTop: "-3px",
                marginLeft: "12px",
                fontSize: "11pt",
                fontWeight: "500",
                color: "#b93e2b",
                cursor: "pointer",
              }}
            >
              <span
                onMouseUp={this.openForgotPwModal}
                onTouchEnd={this.openForgotPwModal}
              >
                Forgot password?
              </span>
            </div>

            <div className="signinSubmitDiv">
              <button
                id="submitButton"
                disabled={this.state.signingIn ? true : false}
                onMouseUp={this.signIn}
                onTouchEnd={this.signIn}
                className="navButton resendSignin z-depth-1"
                disabled={this.state.signingIn ? true : false}
              >
                Signin & Resend
              </button>
              <ForgotPasswordModal />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            fontSize: "16pt",
            fontWeight: "400",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <br />
          <span style={{ fontWeight: "700" }}>Congratulations!</span>
          <br />
          Your email has been verified.
          <br />
          <br />
          You may now communicate with other users using
          <br />
          CoolNeighbors.com or the Cool Neighbors app.
        </div>
      );
    }
  }
}

export default ImprovedEmailVerification;
