import React, {Component} from 'react'


class MessageModal extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return(
      <div>
        <div id="sendMessageModal" className="modal modal-fixed-footer">
          <div className="modal-content noscrollX">
            <h4 className="modalHeading">Message</h4>
            <textarea maxLength="2000" id="messageTextArea" className="messageTextArea"></textarea>

          </div>
          <div className="modal-footer">
          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
            <a onMouseUp={this.props.findCorrespondence} onTouchEnd={this.props.findCorrespondence} className="modal-close waves-effect waves-red btn red darken-3">SEND</a>
          </div>
        </div>
      </div>

    )
  }
}

export default MessageModal;