import React, {Component} from 'react'
import { activities } from '../../cityData/activities';


class ActivitiesModal extends Component {

  render() {
      var modalContent;
      if (this.props.appState.userData.activities === undefined){
        modalContent = <div></div>
      }
      else {
        var checkedActivs = this.props.appState.userData.activities;
        var halfwayPoint = Math.floor(activities.length/2) - 1
        modalContent = 
          <div className="row">
            <div className="col l6 m6">

            {activities.map(activity => {
              if (activities.indexOf(activity) <= halfwayPoint){

                var check = "";
                checkedActivs.includes(activity) ? check = "checked" : check = "";
                
                return(
                  <label key={activity}>
                    <input 
                    defaultChecked={check}
                    value={activity} 
                    id={activity} 
                    className="activity" 
                    type="checkbox" />
                    <span>{activity}</span><br/>
                  </label>
                )
              } 
            })}


            </div>
            <div className="col l6 m6">
              {activities.map(activity => {
                if (activities.indexOf(activity) > halfwayPoint){

                  var check = "";
                  checkedActivs.includes(activity) ? check = "checked" : check = "";
                  

                  return(
                    <label key={activity}>
                      <input 
                      defaultChecked={check}
                      value={activity} 
                      id={activity} 
                      className="activity" 
                      type="checkbox" />
                      <span>{activity}</span><br/>
                    </label>
                  )
                } 
              })}

            </div>
          </div>
      }




    return(
      <div id="activitiesModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">Activities</h4>
          {modalContent}

   
        </div>
        <div className="modal-footer">
          <a onMouseUp={this.props.submitActivities} onTouchEnd={this.props.submitActivities} className="modal-close waves-effect waves-red btn-flat">DONE</a>
        </div>
      </div>
    )
  }
}

export default ActivitiesModal;