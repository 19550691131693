import React, {Component} from 'react'
import { Link } from 'react-router-dom'


class SearchResult extends Component {
  constructor(props) {
    super(props);
    
  }

  componentDidMount = () => {
    
  }

  render() {
    return(
      <Link to={`/${this.props.uid}`}>
      <div className="searchResult">

        <div className="searchItemLeft valign-wrapper">
          <img className="userPic" alt="userPic" src={this.props.pic} />
        </div>

        <div className="searchItemRight">

          <div className="searchResultHeading">{this.props.name}</div>

          <div className="searchResultAbout">{this.props.about}</div>
          
        </div>



      </div>
      </Link>




    )
  }
}

export default SearchResult;