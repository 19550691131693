import React, {Component} from 'react'


class EmailVerification extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    document.title = "Confirmed | Cool Neighbors";

  }

  render() {

    return (
      <div style={{fontSize: "16pt", fontWeight: "400", textAlign: "center", marginTop: "10px"}}>
        <span style={{fontWeight: "700"}}>Congratulations!</span><br/>Your email has been verified.<br/>You may now communicate with other users.
      </div>
    )
  }
}

export default EmailVerification;