import React, {Component} from 'react'
import firebase from "firebase/app";



class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);


  }


  componentDidMount = () => {

  }

  
  componentDidUpdate = () => {


  }

  sendPassReset = () => {

    var emailAddress = document.querySelector("#forgotEmailInput").value;

    var auth = firebase.auth();

    auth.sendPasswordResetEmail(emailAddress).then(() => {

      alert("Email sent.");

    }).catch(error => {
      alert(error)
    });
  }


  render() {


    return (
    <div id="forgotPasswordModal" className="modal modal-fixed-footer">
        <div style={{fontSize: "14pt", textAlign: "center"}} className="modal-content noscrollX">
        
        Enter the email address that you used to sign-up.<br/>A password-reset email will be sent to that email address.<br/><br/>

        <input id="forgotEmailInput" className="browser-default forgotEmailInput" type="email" name="currentEmail" placeholder="Email Address"/>

        <br/>
        <br/>

        <a style={{backgroundColor: "#b93e2b", color: "white", padding: "10px 18px 10px 18px", borderRadius: "5px", fontSize: "11.5pt", boxShadow: "2px 2px 5px rgb(179, 179, 179)"}} onMouseUp={this.sendPassReset} onTouchEnd={this.sendPassReset} className="modal-close waves-effect waves-red">SEND RESET EMAIL </a>


        
        </div>
        <div className="modal-footer">

          <a className="modal-close waves-effect waves-red btn-flat">Cancel</a>
        </div>
    </div>
    )
  }
}

export default ForgotPasswordModal;