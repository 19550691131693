import c0 from "./0.png"
import c1 from "./1.png"
import c2 from "./2.png"
import c3 from "./3.png"
import c4 from "./4.png"
import c5 from "./5.png"
import c6 from "./6.png"
import c7 from "./7.png"
import c8 from "./8.png"
import c9 from "./9.png"
import c10 from "./10.png"
import c11 from "./11.png"
import c12 from "./12.png"
import c13 from "./13.png"
import c14 from "./14.png"
import c15 from "./15.png"
import c16 from "./16.png"
import c17 from "./17.png"
import c18 from "./18.png"
import c19 from "./19.png"

export const caps = [c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14, c15, c16, c17, c18, c19];