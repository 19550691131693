import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { auth, db } from "./fbConfig";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Now from "./components/Now/Now";
import Profile from "./components/Profile/Profile";
// import Search from './components/Search/Search';
import SearchImproved from "./components/SearchImproved/SearchImproved";
import Messages from "./components/Messaging/Messages";
import Correspondence from "./components/Messaging/Correspondence";
import Account from "./components/Account/Account";
import MobileNavBar from "./components/MobileNavBar";
import menu from "./images/menu.png";
import menuMail from "./images/menuMail.png";
import M from "materialize-css";
import { Link } from "react-router-dom";
import User from "./components/User/User";
import Post from "./components/Now/Post";
import EmailVerification from "./components/EmailVerification";
import ImprovedEmailVerification from "./components/ImprovedEmailVerification";
import EmailFailure from "./components/EmailFailure";
import { functions } from "./fbConfig";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
      user: JSON.parse(localStorage.getItem("user")),
      scroll: "",
      width: window.innerWidth,
      userData: {},
      successfulSignup: false,
      signupPending: false,
      mailbox: [],
      connectMailboxOneOnOff: null,
      connectMailboxTwoOnOff: null,
      unreadMessages: false,
      deleteAccount: this.deleteUser,
      recentlyMessaged: [],
      addToRecentlyMessaged: this.addToRecentlyMessaged,
      needToShowEmailReminder: false,
      searchPage: 0,
    };

    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
    // this.sendMessageNotification = functions.httpsCallable(
    //   "sendMessageNotification"
    // );
    this.improvedSendMessageNotification = functions.httpsCallable(
      "improvedSendMessageNotification"
    );

    this.sendPushNotification = functions.httpsCallable("sendPushNotification");
  }

  // OLD FUNCTION?
  addToRecentlyMessaged = (uid) => {
    var recentlyMessaged = this.state.recentlyMessaged;
    recentlyMessaged.push(uid);
    this.setState({
      recentlyMessaged: recentlyMessaged,
    });
  };

  sendPush = (data) => {
    this.sendPushNotification(data).then((result) => {
      console.log(result.data);
    });
  };

  finishSignup = (emailVerifyCode, userId, firstName) => {
    this.improvedSendVerificationEmail({
      emailAddress: this.state.user.email,
      emailVerifyCode: emailVerifyCode,
      firstName: firstName,
      uid: userId,
    }).then(() => {
      console.log("Confirmation email sent.");
      var newEmail = {
        confirmed: false,
        code: emailVerifyCode,
        signupDate: new Date(),
      };
      db.collection("confirmedEmails")
        .doc(userId)
        .set(newEmail)
        .then(() => {
          console.log("Email added to confirm list.");
          this.setState(
            {
              successfulSignup: true,
              signupPending: false,
              needToShowEmailReminder: true,
            },
            this.connectCurrentUser
          );
        });
    });
  };

  signupPendingOn = () => {
    console.log("signup pending on");
    this.setState({
      signupPending: true,
    });
  };

  signupPendingOff = () => {
    console.log("signup pending off");
    this.setState({
      signupPending: false,
    });
  };

  clearSignup = () => {
    this.setState({
      successfulSignup: false,
    });
  };

  navOpen = (e) => {
    // e.preventDefault();
    this.sideNav.open();
    // console.log(e.type)
  };

  navClose = (e) => {
    // e.preventDefault();
    this.sideNav.close();
    // console.log(e)
  };

  componentDidUpdate = () => {
    // console.log("app update")
  };

  componentDidMount = () => {
    if (localStorage.getItem("nowCity") === null) {
      localStorage.setItem("nowCity", "New York City / NY Metropolitan Area");
    }

    auth.onAuthStateChanged((user) => {
      // var scrollHere = document.querySelector("#scrollHere");
      // scrollHere.scrollIntoView();

      if (user) {
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("user", JSON.stringify(user));
        // console.log(user)

        this.setState(
          {
            loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
            user: JSON.parse(localStorage.getItem("user")),
          },
          this.connectCurrentUser
        );
        console.log("User logged in.");
      } else {
        // localStorage.clear();
        localStorage.setItem("loggedIn", null);
        localStorage.setItem("user", null);

        this.setState({
          loggedIn: JSON.parse(localStorage.getItem("loggedIn")),
          user: JSON.parse(localStorage.getItem("user")),
          userData: {},
          mailbox: {},
          connectMailboxOneOnOff: null,
          connectMailboxTwoOnOff: null,
          recentlyMessaged: [],
          unreadMessages: false,
        });
      }
    });

    var sideNav = document.querySelectorAll(".sidenav");
    var sideNavOptions = {
      inDuration: 200,
      outDuration: 175,
    };
    var sideNavInstances = M.Sidenav.init(sideNav, sideNavOptions);
    this.sideNav = sideNavInstances[0];

    if (window.innerWidth <= 600) {
      this.setState({
        appContainerClass: "appContainerSmall",
        navContainerClass: "navContainerSmall",
        contentContainerClass: "contentContainerSmall",
      });
    }
    if (window.innerWidth > 600 && window.innerWidth < 992) {
      this.setState({
        appContainerClass: "appContainerMedium",
        navContainerClass: "navContainerMedium",
        contentContainerClass: "contentContainerMedium",
      });
    }
    if (window.innerWidth >= 992) {
      this.setState({
        appContainerClass: "appContainerLarge",
        navContainerClass: "navContainerLarge",
        contentContainerClass: "contentContainerLarge",
      });
    }

    window.addEventListener("resize", this.resizeEventListener);

    document.addEventListener("touchstart", this.removeFancyScroll);
    document.addEventListener("touchstart", this.removeMouseFunctionality);
    // document.addEventListener("mouseup", this.removeTouchFunctionality);

    // document.addEventListener("touchend", () => console.log("touch end"));
    // document.addEventListener("touchstart", () => console.log("touch start"));
    // document.addEventListener("mouseup", () => console.log("mouse up"));
    // document.addEventListener("mousedown", () => console.log("mouse down"));
    // document.addEventListener("click", () => console.log("click"));

    if (this.state.userData.emailVerification === undefined) {
      this.setState({
        emailVerification: false,
      });
    }

    if (window.innerHeight < 660) {
      this.removeFancyScroll();
    }

    // var scrollHere = document.querySelector("#scrollHere");
    // scrollHere.scrollIntoView(true);

    // COME BACK TO THIS!
    // console.log(navigator.appName);
    // console.log(navigator.appVersion);
    // console.log(navigator.platform);
    console.log(navigator.userAgent);
  };

  resizeEventListener = () => {
    // console.log("ok")
    if (window.innerWidth <= 600) {
      this.setState({
        appContainerClass: "appContainerSmall",
        navContainerClass: "navContainerSmall",
        contentContainerClass: "contentContainerSmall",
      });
    }
    if (window.innerWidth > 600 && window.innerWidth < 992) {
      this.setState({
        appContainerClass: "appContainerMedium",
        navContainerClass: "navContainerMedium",
        contentContainerClass: "contentContainerMedium",
      });
    }
    if (window.innerWidth >= 992) {
      this.setState({
        appContainerClass: "appContainerLarge",
        navContainerClass: "navContainerLarge",
        contentContainerClass: "contentContainerLarge",
      });
    }
    if (window.innerHeight < 660) {
      // console.log("ok")
      this.removeFancyScroll();
    }
  };

  removeFancyScroll = () => {
    console.log("Fancy scroll removed.");
    this.setState({
      scroll: "regularScroll",
    });
    document.body.classList.add("regularScroll");

    // window.removeEventListener("resize", this.resizeEventListener);

    document.removeEventListener("touchstart", this.removeFancyScroll);
  };

  removeMouseFunctionality = () => {
    console.log("Mouse click functionality removed.");
    document.addEventListener(
      "mouseup",
      function (e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      },
      true
    );

    document.addEventListener(
      "click",
      function (e) {
        // console.log(e.target);
        var elementClass = e.target.getAttribute("class");
        // console.log(elementClass)
        if (
          elementClass === "sidenav-overlay" ||
          elementClass === "modal-overlay"
        ) {
          console.log("prevent");
          e.preventDefault();
          e.stopPropagation();
        }
        //     // console.log(allow)
        //     if (allow === "stopPropagation") {
        //       console.log("stopPropagation")
        //       e.stopPropagation();
        //     } else if (allow === "preventDefault") {
        //       console.log("preventDefault")
        //       e.preventDefault();
        //     } else if (allow === "stopBoth") {
        //       console.log("stopBoth")
        //       e.stopPropagation();
        //       e.preventDefault();
        //     } else {
        //       console.log("Full click")
        //     }
      },
      true
    );

    document.removeEventListener("touchstart", this.removeMouseFunctionality);
  };

  connectCurrentUser = () => {
    if (!this.state.signupPending) {
      const uid = this.state.user.uid;

      var connectOnOff = db
        .collection("userData")
        .doc(uid)
        .onSnapshot((doc) => {
          this.setState(
            {
              userData: doc.data(),
              connectOnOff: connectOnOff,
            },
            this.connectCurrentMailboxes
          );

          console.log("User data changed.");
        });
      // .catch(err => {
      //   console.log(err)
      // });
    }
  };

  connectCurrentMailboxes = () => {
    const uid = this.state.user.uid;
    var messagesRef = db.collection("messages");

    // THIS SETS DISPLAY NAME - UNRELATED TO MAILBOXES - LEGACY: No longer really needed since we moved back to AWS SES for email verification
    // console.log(this.state.userData)
    if (auth.currentUser && this.state.userData !== undefined) {
      if (auth.currentUser.displayName !== this.state.userData.firstName) {
        auth.currentUser
          .updateProfile({
            displayName: this.state.userData.firstName,
          })
          .then(() => {
            console.log("Display name updated.");
          });
      } else {
        // console.log("Display name already matches.");
        // console.log(auth.currentUser.emailVerified);
      }
    }

    // if (!auth.currentUser.emailVerified){
    //   console.log("EMAIL NOT VERIFIED!")
    // }

    // FROM FIREBASE DOCS - Query limitations for logical OR queries: In this case, you should create a separate query for each OR condition and merge the query results in your app.
    if (
      !this.state.connectMailboxOneOnOff &&
      !this.state.connectMailboxTwoOnOff
    ) {
      var firstQuery = messagesRef.where("correspondentOne", "==", uid);
      var connectMailboxOneOnOff = firstQuery.onSnapshot(
        this.firstQueryGetSnap
      );

      var secondQuery = messagesRef.where("correspondentTwo", "==", uid);
      var connectMailboxTwoOnOff = secondQuery.onSnapshot(
        this.secondQueryGetSnap
      );

      this.setState({
        connectMailboxOneOnOff: connectMailboxOneOnOff,
        connectMailboxTwoOnOff: connectMailboxTwoOnOff,
      });
      console.log("Mailboxes connected.");
    }
  };

  firstQueryGetSnap = (querySnapshot) => {
    // console.log("--- FIRST QUERY ---");
    this.mailboxOne = [];
    querySnapshot.forEach((doc) => {
      this.mailboxOne.push(doc.data());
    });
    this.resetMailbox();
  };

  secondQueryGetSnap = (querySnapshot) => {
    // console.log("--- SECOND QUERY ---");
    this.mailboxTwo = [];
    querySnapshot.forEach((doc) => {
      this.mailboxTwo.push(doc.data());
    });
    this.resetMailbox();
  };

  resetMailbox = () => {
    var combinedMailbox = [];
    if (this.mailboxOne && this.mailboxTwo) {
      combinedMailbox = this.mailboxOne.concat(this.mailboxTwo);
      this.organizeMailbox(combinedMailbox);
    }
  };

  organizeMailbox = (combinedMailbox) => {
    // console.log(combinedMailbox)

    var mailboxByTimestamp = [];
    for (var i = 0; i < combinedMailbox.length; i++) {
      var highestTimestamp = 0;
      var topMessage = "";

      var messages = combinedMailbox[i].data;
      var messageKeys = Object.keys(messages);
      // console.log("-------------");
      for (var j = 0; j < messageKeys.length; j++) {
        var key = messageKeys[j];
        // console.log(messages[key].timeStamp);
        if (messages[key].timeStamp > highestTimestamp) {
          highestTimestamp = messages[key].timeStamp;
          topMessage = messages[key];
        }
      }
      mailboxByTimestamp.push([
        combinedMailbox[i],
        highestTimestamp,
        topMessage.receiver,
        topMessage.readByReceiver,
      ]);
    }

    mailboxByTimestamp = mailboxByTimestamp.sort((a, b) => {
      a = a[1];
      b = b[1];
      return b - a;
    });

    var unreadMessages = false;
    mailboxByTimestamp.forEach((correspondence) => {
      if (this.state.userData !== undefined) {
        if (
          correspondence[2] === this.state.userData.uid &&
          correspondence[3] === false
        ) {
          unreadMessages = true;
        }
      }
    });

    this.setState({
      mailbox: mailboxByTimestamp,
      unreadMessages: unreadMessages,
    });

    console.log("Mailboxes updated.");

    if (this.state.unreadMessages) {
      console.log("Unread messages!");
    }
    // console.log(this.state);
  };

  sendNotification = (uid, name, message, newCorrespondence) => {
    if (newCorrespondence === true) {
      const userRef = db.collection("confirmedEmails").doc(uid);
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            var confirmed = doc.data().confirmed;
            if (confirmed) {
              console.log("Other user's email address has been confirmed.");
              this.improvedSendMessageNotification({
                uid: uid,
                senderName: name,
                message: message,
              })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              console.log("The other user's email address is not confirmed.");
            }
          } else {
            console.log("The other user could not be found.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.improvedSendMessageNotification({
        uid: uid,
        senderName: name,
        message: message,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  deleteUser = () => {
    var deleted = {
      accountDeleted: true,
      notificationTokens: [],
    };

    let usersRef = db.collection("userData").doc(this.state.user.uid);
    usersRef.update(deleted).then(() => {
      console.log("About to delete...");
      this.state.connectOnOff();
      this.state.connectMailboxOneOnOff();
      this.state.connectMailboxTwoOnOff();

      var user = auth.currentUser;

      user
        .delete()
        .then((result) => {
          alert("Your account has been deleted.");
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    });
  };

  toggleVerificationReminder = () => {
    this.setState({
      needToShowEmailReminder: false,
    });
  };

  increaseSearchPage = () => {
    this.setState({
      searchPage: this.state.searchPage + 1,
    });
  };

  decreaseSearchPage = () => {
    this.setState({
      searchPage: this.state.searchPage - 1,
    });
  };

  resetSearchPage = () => {
    this.setState({
      searchPage: 0,
    });
  };

  improvedCheckEmailVerification = (userId) => {
    const userRef = db.collection("confirmedEmails").doc(userId);
    return new Promise((resolve, reject) => {
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data().confirmed);
          } else {
            resolve("Record not found.");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // touchLink = (slug) => {
  //   if (window.location.pathname !== slug) {
  //     window.location.pathname = slug;
  //   }
  // }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          {/* MOBILE NAVBAR */}
          <img
            onTouchEnd={this.navOpen}
            onMouseUp={this.navOpen}
            id="menuIcon"
            src={this.state.unreadMessages ? menuMail : menu}
            alt="stopPropagation"
            className={
              this.state.contentContainerClass === "contentContainerSmall"
                ? ""
                : "hide"
            }
          />

          <MobileNavBar navClose={this.navClose} appState={this.state} />
          {/* END MOBILE NAVBAR */}

          <div className={this.state.appContainerClass}>
            {/* REGULAR NAVBAR */}
            <div className={this.state.navContainerClass}>
              <NavBar appState={this.state} />
            </div>

            {/* REGULAR NAVBAR END*/}

            <div
              id="contentContainer"
              className={`${this.state.contentContainerClass} ${this.state.scroll}`}
            >
              {/* <div id="scrollHere"></div> */}
              <div style={{ textAlign: "right" }}>
                <Link to="/">
                  <img
                    className={`navLogoSmall ${
                      this.state.contentContainerClass ===
                      "contentContainerSmall"
                        ? ""
                        : "hide"
                    }`}
                    src="logo.svg"
                    alt="Cool Neighbors"
                  />
                </Link>
              </div>

              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Home
                      {...props}
                      clearSignup={this.clearSignup}
                      navOpen={this.navOpen}
                      appState={this.state}
                    />
                  )}
                />

                <Route
                  path="/signup"
                  render={(props) => (
                    <SignUp
                      {...props}
                      signupPendingOn={this.signupPendingOn}
                      signupPendingOff={this.signupPendingOff}
                      appState={this.state}
                      finishSignup={this.finishSignup}
                    />
                  )}
                />

                <Route
                  path="/signin"
                  render={(props) => (
                    <SignIn {...props} appState={this.state} />
                  )}
                />

                <Route
                  path="/now"
                  render={(props) => (
                    <Now
                      {...props}
                      appState={this.state}
                      improvedCheckEmailVerification={
                        this.improvedCheckEmailVerification
                      }
                    />
                  )}
                />

                <Route
                  path="/profile"
                  render={(props) => (
                    <Profile
                      toggleVerificationReminder={
                        this.toggleVerificationReminder
                      }
                      {...props}
                      appState={this.state}
                    />
                  )}
                />

                {/* <Route path="/search" render={(props) => <Search {...props} appState={this.state}/>} /> */}

                <Route
                  path="/search"
                  render={(props) => (
                    <SearchImproved
                      {...props}
                      appState={this.state}
                      increaseSearchPage={this.increaseSearchPage}
                      decreaseSearchPage={this.decreaseSearchPage}
                      resetSearchPage={this.resetSearchPage}
                    />
                  )}
                />

                <Route
                  path="/messages"
                  render={(props) => (
                    <Messages {...props} appState={this.state} />
                  )}
                />

                <Route
                  path="/account"
                  render={(props) => (
                    <Account {...props} appState={this.state} />
                  )}
                />

                <Route
                  path="/correspondence"
                  render={(props) => (
                    <Correspondence
                      {...props}
                      sendNotification={this.sendNotification}
                      appState={this.state}
                      improvedCheckEmailVerification={
                        this.improvedCheckEmailVerification
                      }
                      sendPush={this.sendPush}
                    />
                  )}
                />

                <Route
                  path="/post"
                  render={(props) => (
                    <Post
                      {...props}
                      appState={this.state}
                      sendNotification={this.sendNotification}
                      improvedCheckEmailVerification={
                        this.improvedCheckEmailVerification
                      }
                      sendPush={this.sendPush}
                    />
                  )}
                />

                <Route
                  path="/verifyemail"
                  render={(props) => (
                    <EmailVerification {...props} appState={this.state} />
                  )}
                />

                <Route
                  path="/verified"
                  render={(props) => (
                    <ImprovedEmailVerification
                      {...props}
                      appState={this.state}
                    />
                  )}
                />

                <Route
                  path="/verifyfailure"
                  render={(props) => (
                    <EmailFailure {...props} appState={this.state} />
                  )}
                />

                <Route
                  path="/privacy"
                  render={(props) => (
                    <PrivacyPolicyPage {...props} appState={this.state} />
                  )}
                />

                <Route
                  exact
                  path="/:user_id"
                  render={(props) => (
                    <User
                      {...props}
                      appState={this.state}
                      improvedCheckEmailVerification={
                        this.improvedCheckEmailVerification
                      }
                      sendNotification={this.sendNotification}
                      sendPush={this.sendPush}
                    />
                  )}
                />

                {/* <Route path="/correpondence/:conversation" render={(props) => <Correspondence {...props} appState={this.state}/>} /> */}

                {/* <Route path="*" component={BlankComponent} /> */}
              </Switch>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
