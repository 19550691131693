import React, { Component } from "react";
import { db } from "../../fbConfig";
import M from "materialize-css";
import SendMessage from "./SendMessage.js";
import Flag from "./Flag.js";
import CanNotFlag from "./CanNotFlag.js";
import { Redirect } from "react-router-dom";
import { functions } from "../../fbConfig";
import { Link } from "react-router-dom";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherUserData: null,
      messageSent: false,
      verifyingEmailAddress: "",
    };

    this.messageText = "";

    this.getServerTimestamp = functions.httpsCallable("getServerTimestamp");
    this.improvedSendVerificationEmail = functions.httpsCallable(
      "improvedSendVerificationEmail"
    );
  }

  componentDidMount = () => {
    document.title = "User | CoolNeighbors";

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch (e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    let otherUserId = this.props.match.params.user_id;
    const userRef = db.collection("userData").doc(otherUserId);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            otherUserData: doc.data(),
          });

          // scrollHere.scrollIntoView();

          try {
            content.scrollTo(0, 0);
            window.scrollTo(0, 0);
          } catch (e) {
            content.scrollTop = 0;
            window.scrollTop = 0;
          }
        } else {
          this.setState({
            otherUserData: "User not found.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    var materialImages = document.querySelectorAll(".materialboxed");
    var imageOptions = {};
    this.materialBoxInstances = M.Materialbox.init(
      materialImages,
      imageOptions
    );

    this.getServerTimestamp().then((result) => {
      var d = new Date(result.data);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var timeStamp = result.data / 1000;
      var secondsSinceMidnight = (hours * 60 + minutes) * 60;

      var lateNight = false;
      if (hours < 4) {
        lateNight = true;
      }

      if (!lateNight) {
        var secondsSince4am = secondsSinceMidnight - 14400;
        var timestampAt4am = timeStamp - secondsSince4am;
        var milliTimestampAt4am = timestampAt4am * 1000;
        this.setState({
          cutoffTimeStamp: milliTimestampAt4am,
        });
      } else {
        var secondsSince4amYesterday = secondsSinceMidnight + 72000;
        var timeStamp4amYesterday = timeStamp - secondsSince4amYesterday;
        var milliTimestamp4amYesterday = timeStamp4amYesterday * 1000;
        this.setState({
          cutoffTimeStamp: milliTimestamp4amYesterday,
        });
      }
      // this.setState({
      //   serverTimeStamp: result.data
      // })
    });

    var flagModals = document.querySelectorAll(".flagModal");
    var modalOptions = {};
    this.flagModals = M.Modal.init(flagModals, modalOptions);
  };

  componentDidUpdate = () => {
    var materialImages = document.querySelectorAll(".materialboxed");
    var imageOptions = {};
    this.materialBoxInstances = M.Materialbox.init(
      materialImages,
      imageOptions
    );

    if (this.flagModals.length === 0) {
      var flagModals = document.querySelectorAll(".flagModal");
      var modalOptions = {};
      this.flagModals = M.Modal.init(flagModals, modalOptions);
    }
  };

  findCorrespondence = () => {
    var messageBox = document.querySelector("#messageTextArea");
    if (messageBox.value !== "") {
      var message = messageBox.value;
      let receiverId = this.props.match.params.user_id;
      let senderId = this.props.appState.user.uid;
      let rAnds = receiverId + " and " + senderId;
      let sAndr = senderId + " and " + receiverId;

      var listOfCorrespondences = [];
      var listOfCorrespondenceMoreData = [];

      this.props.appState.mailbox.forEach((convo) => {
        listOfCorrespondences.push(convo[0].correspondence);
        // BELOW MAKES LIST OF 0:correspondence ID, 1:highestTimestamp, 2: receiver of most recent message - used later to figure out if notification should be sent
        listOfCorrespondenceMoreData.push([
          convo[0].correspondence,
          convo[1],
          convo[2],
        ]);
      });

      var firstCheck = listOfCorrespondences.includes(rAnds);
      var secondCheck = listOfCorrespondences.includes(sAndr);

      var extraConvoData;
      if (firstCheck && !secondCheck) {
        console.log("Correspondence found! (First check.)");

        listOfCorrespondenceMoreData.forEach((item) => {
          if (item[0] === rAnds) {
            extraConvoData = item;
          }
        });

        this.postMessage(
          message,
          rAnds,
          receiverId,
          senderId,
          "old",
          extraConvoData
        );
      } else if (!firstCheck && secondCheck) {
        console.log("Correspondence found! (Second check.)");

        listOfCorrespondenceMoreData.forEach((item) => {
          if (item[0] === sAndr) {
            extraConvoData = item;
          }
        });

        this.postMessage(
          message,
          sAndr,
          receiverId,
          senderId,
          "old",
          extraConvoData
        );
      } else {
        console.log("Correspondence not found!");
        this.postMessage(message, sAndr, receiverId, senderId, "new");
      }
    }
  };

  postMessage = (
    message,
    correspondence,
    receiverId,
    senderId,
    oldNew,
    extraConvoData
  ) => {
    var messagesRef = db.collection("messages");
    var correspondenceRef = messagesRef.doc(correspondence);

    var timeStamp;

    this.getServerTimestamp().then((result) => {
      timeStamp = result.data;
      var timeStampKey = timeStamp + "-" + senderId;

      if (oldNew === "new") {
        correspondenceRef
          .set({
            correspondentOne: senderId,
            correspondentTwo: receiverId,
            correspondence: correspondence,
            lastMessageTime: timeStamp,
            data: {
              [timeStampKey]: {
                message,
                test: "test",
                sender: senderId,
                receiver: receiverId,
                senderName: this.props.appState.userData.firstName,
                receiverName: this.state.otherUserData.firstName,
                timeStamp: timeStamp,
                readBySender: true,
                readByReceiver: false,
              },
            },
          })
          .then(() => {
            console.log("Message successfully sent!");
            if (this.state.otherUserData.receiveNotifications) {
              this.props.sendNotification(
                this.state.otherUserData.uid,
                this.props.appState.userData.firstName,
                message,
                true
              );
            }

            this.setState({
              messageSent: true,
              correspondenceSent: correspondence.replace(/ /g, "-"),
            });
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      } else {
        correspondenceRef
          .set(
            {
              lastMessageTime: timeStamp,
              data: {
                [timeStampKey]: {
                  message,
                  test: "test",
                  sender: senderId,
                  receiver: receiverId,
                  senderName: this.props.appState.userData.firstName,
                  receiverName: this.state.otherUserData.firstName,
                  timeStamp: timeStamp,
                  readBySender: true,
                  readByReceiver: false,
                },
              },
            },
            { merge: true }
          )
          .then(() => {
            console.log("Message successfully sent!");
            if (this.state.otherUserData.receiveNotifications) {
              // if (this.props.appState.recentlyMessaged.includes(this.state.otherUserData.uid) === false){
              // this.props.appState.addToRecentlyMessaged(this.state.otherUserData.uid);

              if (
                extraConvoData[2] === this.state.otherUserData.uid &&
                timeStamp - extraConvoData[1] > 86400000
              ) {
                console.log(
                  "A notification was sent because I sent the last message and it has been over 24 hours since that last message."
                );
                this.props.sendNotification(
                  this.state.otherUserData.uid,
                  this.props.appState.userData.firstName,
                  message
                );
              } else if (
                extraConvoData[2] !== this.state.otherUserData.uid &&
                timeStamp - extraConvoData[1] > 600000
              ) {
                console.log(
                  "A notification was sent because I did not send the last message and it has been 10 min since the other person sent their message."
                );
                this.props.sendNotification(
                  this.state.otherUserData.uid,
                  this.props.appState.userData.firstName,
                  message
                );
              } else {
                console.log("No notification was sent.");
              }

              // }
            }

            this.setState({
              messageSent: true,
              correspondenceSent: correspondence.replace(/ /g, "-"),
            });
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }

      if (this.state.otherUserData.notificationTokens !== undefined && this.state.otherUserData.notificationTokens.length !== 0) {
        console.log("SENDING PUSH!")
        this.props.sendPush({
          name: this.props.appState.userData.firstName,
          tokens: this.state.otherUserData.notificationTokens,
          message: message
        });
      }


    });
  };

  openFlagModal = () => {
    if (
      this.props.appState.userData.flagged.includes(
        this.state.otherUserData.uid
      )
    ) {
      alert("You've flagged this user.");
    } else if (this.props.appState.userData.emailVerified) {
      this.flagModals[0].open();
    } else {
      this.setState({
        verifyingEmailAddress: "pending",
      });

      this.props.improvedCheckEmailVerification(this.props.appState.userData.uid).then(
        (emailVerification) => {
          // var emailVerification =
          //   result.data.VerificationAttributes[this.props.appState.user.email]
          //     .VerificationStatus;

          if (emailVerification === true) {
            console.log("Email verified!");
            var emailVerified = {
              emailVerified: true,
            };
            let usersRef = db
              .collection("userData")
              .doc(this.props.appState.user.uid);
            usersRef.update(emailVerified).then(() => {
              this.setState({
                verifyingEmailAddress: "",
              });
              this.flagModals[0].open();
            });
          } else {
            console.log("Email not verified!");
            this.setState({
              verifyingEmailAddress: "",
            });
            this.flagModals[1].open();
          }
        }
      );
    }
  };

  resendVerificationEmail = () => {
    this.improvedSendVerificationEmail({
      emailAddress: this.props.appState.user.email,
      emailVerifyCode: this.props.appState.userData.emailVerifyCode,
      firstName: this.props.appState.userData.firstName,
      uid: this.props.appState.userData.uid,
    }).then((result) => {
      console.log("Confirmation email sent.");
      alert("Email sent.");
    });
  };

  sendFlag = () => {
    var flagNodes = document.querySelectorAll(".flag");
    var date = new Date();
    var flagInfo = {
      [date]: {
        sexual: flagNodes[0].checked,
        hateful: flagNodes[1].checked,
        selling: flagNodes[2].checked,
        other: flagNodes[3].value,
        flagger: this.props.appState.user.uid,
        flaggee: this.state.otherUserData.uid,
      },
    };

    var alreadyFlagged = this.props.appState.userData.flagged;
    alreadyFlagged.push(this.state.otherUserData.uid);

    let flagsRef = db.collection("flags").doc(this.state.otherUserData.uid);
    flagsRef
      .set(flagInfo, { merge: true })
      .then(() => {
        var flagged = {
          flagged: alreadyFlagged,
        };
        let usersRef = db
          .collection("userData")
          .doc(this.props.appState.user.uid);
        usersRef.update(flagged).then(() => {
          alert("User flagged.");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkQuotes = (e) => {
    e.target.value = e.target.value.replace(/\ "/g, " “");
  };

  render() {
    // console.log(this.props);
    if (this.state.otherUserData === null) {
      return <span></span>;
    }
    if (this.state.otherUserData === "User not found.") {
      return <Redirect to="/" />;
    }
    if (this.props.appState.userData.flagged !== undefined){
      if (
        this.props.appState.userData.flagged.includes(
          this.state.otherUserData.uid
        )
      ) {
        return (
          <div style={{ fontSize: "14pt", fontWeight: "400" }}>
            This user's profile is no longer available.
          </div>
        );
      }
    }
    

    if (
     this.state.otherUserData.flagged.includes(this.props.appState.userData.uid)
    ) {
      return (
        <div style={{ fontSize: "14pt", fontWeight: "400" }}>
          This user's profile is no longer available.
        </div>
      );
    }


    if (this.state.messageSent === true) {
      return (
        <Redirect
          push
          to={`/correspondence?id=${this.state.correspondenceSent}`}
        />
      );
    }
    if (this.state.otherUserData.accountDeleted) {
      return (
        <div style={{ fontSize: "14pt", fontWeight: "400" }}>
          This user's profile is no longer available.
        </div>
      );
    } else {
      const otherUserData = this.state.otherUserData;
      const otherUsersFlags = this.state.otherUserData.flagged;
      const otherUserId = this.state.otherUserData.uid;

      var activePostLink = <span></span>;
      if (otherUserData.postData) {
        if (otherUserData.postData.timeStamp > this.state.cutoffTimeStamp) {
          // console.log("User has active post");
          activePostLink = (
            <Link
              style={{ textAlign: "center" }}
              to={`/post?id=${otherUserData.uid}`}
            >
              <div
                style={{
                  fontSize: "14pt",
                  color: "#b93e2b",
                  fontWeight: "400",
                  marginTop: "26px",
                  maxWidth: "810px",
                }}
              >
                View user's active “Now” post.
              </div>
            </Link>
          );
        }
      }

      var flagButton;

      if (!this.props.appState.loggedIn) {
        flagButton = (
          <button disabled className="flagButton">
            Flag
          </button>
        );
      } else if (this.state.verifyingEmailAddress === "pending") {
        flagButton = (
          <button disabled className="flagButton">
            Flag
          </button>
        );
      } else if (
        this.props.appState.user.uid === this.props.match.params.user_id
      ) {
        flagButton = <button className="flagButton">Flag</button>;
      } else {
        flagButton = (
          <button
            onMouseUp={this.openFlagModal}
            onTouchEnd={this.openFlagModal}
            className="flagButton"
          >
            Flag
          </button>
        );
      }
      // console.log(this.props.appState.loggedIn)

      return (
        <div
          style={{ maxWidth: "850px" }}
          className={
            this.props.appState.appContainerClass === "appContainerSmall"
              ? "profileSmall"
              : "profileLarge"
          }
        >
          <img
            alt="desktopUserPic"
            src={otherUserData.picURL}
            className="desktopUserPic materialboxed"
          />

          <div className="userHeading">{otherUserData.firstName}</div>
          <br />

          <span className="profileHeadingLarge">About:</span>
          <div style={{ whiteSpace: "pre-wrap" }} className="profileText">
            {otherUserData.about}
          </div>

          <span className="profileHeadingLarge">Interests & Activities:</span>
          <div className="profileText">
            {otherUserData.activities.join(", ")}
          </div>

          <span className="profileHeadingLarge">City:</span>
          <div className="profileText">{otherUserData.city}</div>

          <span className="profileHeadingLarge">Neighborhoods:</span>
          <div className="profileText">
            {otherUserData.neighborhoods.join(", ")}
          </div>

          <SendMessage
            messageText={this.state.messageText}
            checkQuotes={this.checkQuotes}
            appState={this.props.appState}
            otherUsersFlags={otherUsersFlags}
            otherUserId={otherUserId}
            currentUserId={this.props.appState.userData.uid}
            findCorrespondence={this.findCorrespondence}
            improvedCheckEmailVerification={this.props.improvedCheckEmailVerification}
          />

          <div style={{ textAlign: "center", maxWidth: "800px" }}>
            {flagButton}
          </div>

          <Flag sendFlag={this.sendFlag} />
          <CanNotFlag resendVerificationEmail={this.resendVerificationEmail} />

          {activePostLink}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }
  }
}

export default User;
