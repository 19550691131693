import React, {Component} from 'react'
import { neighborhoods } from '../../cityData/hoods';
import M from 'materialize-css'


class CityModal extends Component {
  constructor(props) {
    super(props);
    
  }

  componentDidMount = () => {
    this.modal = document.querySelector('#cityModal');
    var modalOptions = {};
    this.cityModal = M.Modal.init(this.modal, modalOptions);
    this.props.captureModals(this.cityModal);
    

  }

  render() {
    var cities = Object.keys(neighborhoods);
    var modalContent;

    // if (!this.props.appState.loggedIn){

    // }
    // if (this.props.appState.userData.citySearch === undefined){
    //   modalContent = <div></div>
    // }
    // else {
      modalContent = cities.map(city => {
        return(
          <p key={city}>
          <label htmlFor={city}>
            <input onMouseUp={this.props.handleCityChange} onTouchEnd={this.props.handleCityChange} id={city} type="radio" name="city" className="city" value={city} />
            <span>{city}</span>
          </label>
          </p>
        )
      })
    // }


    return(
      <div id="cityModal" className="modal modal-fixed-footer">
        <div className="modal-content noscrollX">
          <h4 className="modalHeading">City</h4>
          {modalContent}

   
        </div>
        <div className="modal-footer">
          <a onMouseUp={this.props.submitCity} onTouchEnd={this.props.submitCity} className="modal-close waves-effect waves-red btn-flat">SEARCH</a>
        </div>
      </div>
    )
  }
}

export default CityModal;