import React, {Component} from 'react'
import neighborhood from '../images/building.jpg'
import { Redirect } from 'react-router-dom'
import { auth } from '../fbConfig'
import M from 'materialize-css'
import ForgotPasswordModal from "./ForgotPasswordModal.js"


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successfulLogin: false,
      signingIn: false
    }
  }
  

  getInputs = () => {
    this.setState({
      signingIn: true
    })

    let inputs = document.querySelectorAll("input, textarea");
    let formValues = {};
    inputs.forEach((input) =>
      formValues[input.name] = input.value
    );

    const email = formValues.email;
    const password = formValues.password;
    auth.signInWithEmailAndPassword(email, password)
      .then(cred => {
        this.setState({
          signingIn: false
        })
    }).catch(err =>{
        console.log(err)
        alert(err);
        this.setState({
          signingIn: false
        })
    });
  }

  componentDidMount = () => {

    document.title = "Sign-In | Cool Neighbors";

    var content = document.getElementById("contentContainer");
    try {
      content.scrollTo(0, 0);
      window.scrollTo(0, 0);
    } catch(e) {
      content.scrollTop = 0;
      window.scrollTop = 0;
    }

    var pwInput = document.querySelector("#pw")
    pwInput.addEventListener("keyup", event => {

      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        // document.getElementById("submitButton").click();
        this.getInputs();
      }
    });

    var modals = document.querySelectorAll('.modal');
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);
  }

  componentDidUpdate = () => {
    var modals = document.querySelectorAll('.modal');
    var modalOptions = {};
    this.modals = M.Modal.init(modals, modalOptions);
    console.log(this.modals)
  }

  openForgotPwModal = () => {
    this.modals[0].open();
  }

  render() {
    if (this.props.appState.loggedIn === true){
      return <Redirect to="/profile" />
    }
      

    else {
      // console.log(this.props.history)
      return (
          <div>
          <img className="frontImg" src={neighborhood} alt="neighborhood" />
            <h2 className="heading2">Sign-In</h2>
            <div>
            <input className="browser-default signinInput" type="email" name="email" placeholder="Email"/><br/>

            <input id="pw" className="browser-default signinInput" type="password" name="password" placeholder="Password"/><br/>

            <div style={{marginTop: "-3px", marginLeft: "12px", fontSize: "11pt", fontWeight: "500", color: "#b93e2b", cursor: "pointer"}}><span onMouseUp={this.openForgotPwModal} onTouchEnd={this.openForgotPwModal}>Forgot password?</span></div>

            <div className="signinSubmitDiv"><button id="submitButton" disabled={this.state.signingIn ? true : false} onMouseUp={this.getInputs} onTouchEnd={this.getInputs} className="navButton submitSignup z-depth-1">Submit</button></div>
            </div><br/><br/><br/>

            <ForgotPasswordModal />
            <br/><br/><br/><br/>
          </div>
      )
    }
  }
}

export default SignIn;