import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../fbConfig'
import newMailNav from "../images/newMailNav.png"


class NavBar extends Component {  

  constructor(props) {
    super(props);

  }

  logOut = () => {
    this.props.appState.connectOnOff();
    this.props.appState.connectMailboxOneOnOff();
    this.props.appState.connectMailboxTwoOnOff();
    auth.signOut().then(() => {
      console.log("Logged out.");
    });
  }


  componentDidMount = () => {


  }

  componentDidUpdate = () => {

    
  }

  setMailIcon = () => {
    // var messagesButton = document.querySelector("#messagesButton");
    // var newMailIcon = document.querySelector("#newMail");

    // if (messagesButton && newMailIcon) {

    //   // var messageButtonLoction = messagesButton.getBoundingClientRect();
    //   var x = messagesButton.offsetLeft;
    //   var y = messagesButton.offsetTop;

    //   newMailIcon.style.top = y + "px";
    //   newMailIcon.style.left = x + "px";

    //   console.log(messagesButton.offsetTop)
    //   console.log(messagesButton.offsetLeft)
      // console.log(messageButtonLoction);
      // alert("scroll")
    // }
  }


  render() {
    if (this.props.appState.signupPending === true) {
      return(
        <div>

        <div className="logoContainer">
        <Link to="/">
        
        <img className="navLogo" src="logo.svg" alt="Cool Neighbors" />
        
        </Link>
        <br/>
        </div>

        <div className="subNav">
          <Link to="/signup"><button disabled className="navButton">Sign-Up!</button></Link><br/>
          <Link to="/signin"><button disabled className="navButton">Sign-In</button></Link><br/>
          <Link to="/search"><button disabled className="navButton">Search</button></Link><br/>
          <Link to="/now"><button disabled className="navButton now">Now!</button></Link><br/>
          <Link to="/profile"><button disabled className="navButton">Profile</button></Link><br/>
          <Link to="/messages"><button disabled className="navButton">Messages</button></Link><br/>
          <Link to="/account"><button disabled className="navButton">Account</button></Link>
        </div>

        </div>
      )

    } 
    else if (this.props.appState.loggedIn === null){
      return(
        <div>

        <div className="logoContainer">
        <Link to="/">
        
        <img className="navLogo" src="logo.svg" alt="Cool Neighbors" />
        
        </Link>
        <br/>
        </div>

        <div className="subNav">
          <Link to="/signup"><button className="navButton">Sign-Up!</button></Link><br/>
          <Link to="/signin"><button className="navButton">Sign-In</button></Link><br/>
          <Link to="/search"><button className="navButton">Search</button></Link><br/>
          <Link to="/now"><button className="navButton now">Now!</button></Link><br/>
          
          <Link to="/profile"><button disabled className="navButton">Profile</button></Link><br/>
          <Link to="/messages"><button disabled className="navButton">Messages</button></Link><br/>
          <Link to="/account"><button disabled className="navButton">Account</button></Link>
        </div>

        </div>
      )
    } 
    else {

      var newMail = false;
      // console.log(this.props.appState)

      return(
        <div>
        <div className="logoContainer">
        <Link to="/"><img className="navLogo" src="logo.svg" alt="Cool Neighbors" /></Link><br/>
        </div>

        <div className="subNav">
          <Link to="/profile"><button className="navButton">Profile</button></Link><br/>
          <Link to="/search"><button className="navButton">Search</button></Link><br/>
          <Link to="/now"><button className="navButton now">Now!</button></Link><br/>
          
          <Link to="/messages"><img className={this.props.appState.unreadMessages ? "" : "hide"} src={newMailNav} id="newMail"/></Link>

          <Link id="messagesButton" to="/messages"><button className={`navButton`}>Messages</button></Link><br/>


          <Link to="/account"><button className="navButton">Account</button></Link><br/>
          <Link to="/"><button onMouseUp={this.logOut} onTouchEnd={this.logOut} className="navButton">Logout</button></Link><br/>
        </div>
        </div>
      )
    }
  }
}

export default NavBar;
