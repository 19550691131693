import React, {Component} from 'react'


class VerificationReminder extends Component {
  constructor(props) {
    super(props);

  }


  componentDidMount = () => {


  }


  componentDidUpdate = () => {


  }

  

  render() {
    return(
      <div id="verificationReminder" className="modal">
        <div style={{fontSize: "13.5pt", textAlign: "center", marginTop: "12px"}} className="modal-content noscrollX">
        
        <span style={{fontWeight: "600"}}>Note:</span>&ensp;You must confirm your email address before communicating with other users.&ensp;Check your inbox (or in rare circumstances your spam folder) for a verification email from Cool Neighbors.<br/><br/>

        Your email address will never be shared with third parties or other Cool Neighbors users.
        <br/><br/>

        <a style={{fontSize: "12.5pt", backgroundColor: "#b93e2b", color: "white", borderRadius: "5px", boxShadow: "2px 2px 5px rgb(179, 179, 179)", width: "100px", height: "38px"}} className="modal-close waves-effect waves-red btn-flat">OK</a><br style={{marginBottom: "16px"}}/>

        </div>

      </div>
    )
  }
}

export default VerificationReminder;