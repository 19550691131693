import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { auth } from '../fbConfig'
import newMailNav from "../images/newMailNav.png"



class MobileNavBar extends Component {


  logOut = (e) => {
    this.props.appState.connectOnOff();
    this.props.appState.connectMailboxOneOnOff();
    this.props.appState.connectMailboxTwoOnOff();

    this.props.navClose(e);

    auth.signOut().then(() => {
      console.log("Logged out.");
    });


  }
  


  componentDidMount = () => {

  }


  render() {
    //const popOutNav = <div id="slide-out" className="sidenav">TEST!!</div>
    // if (this.state.linkedTouched) {
    //   return <Redirect to={this.state.link} />
    // }

    if (this.props.appState.signupPending === true) {
      return (
        <div className="sidenav" style={{ width: "200px" }}>

          <div className="subNavSmall">
            <br />
            <Link to="/signup"><button disabled className="navSmallButton">Sign-Up!</button></Link><br />
            <Link to="/signin"><button disabled className="navSmallButton">Sign-In</button></Link><br />
            <Link to="/search"><button disabled className="navSmallButton">Search</button></Link><br />
            <Link to="/now"><button disabled className="now navSmallButton">Now!</button></Link><br />
            <Link to="/profile"><button disabled className="navSmallButton">Profile</button></Link><br />
            <Link to="/messages"><button disabled className="navSmallButton">Messages</button></Link><br />
            <Link to="/account"><button disabled className="navSmallButton">Account</button></Link>
          </div>

        </div>
      )
    }

    else if (this.props.appState.loggedIn === null) {
      return (
        <div className="sidenav" style={{ width: "200px" }}>

          <div className="subNavSmall">
            <br />
            <Link to="/signup"><button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Sign-Up!</button></Link><br />
            <Link to="/signin"><button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Sign-In</button></Link><br />
            <Link to="/search"><button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Search</button></Link><br />
            <Link to="/now"><button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="now navSmallButton">Now!</button></Link><br />

            <Link to="/profile"><button disabled className="navSmallButton">Profile</button></Link><br />
            <Link to="/messages"><button disabled className="navSmallButton">Messages</button></Link><br />
            <Link to="/account"><button disabled className="navSmallButton">Account</button></Link>
          </div>

        </div>
      )
    } else {
      return (
        <div className="sidenav" style={{ width: "200px" }}>

          <div className="subNavSmall">
            <br />
            <Link to="/profile">
              <button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Profile</button>
            </Link><br />

            <Link to="/search">
              <button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Search</button>
            </Link><br />

            <Link to="/now">
              <button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="now navSmallButton">Now!</button>
            </Link><br />



            <Link to="/messages"><img onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className={this.props.appState.unreadMessages ? "" : "hide"} src={newMailNav} id="newMailSmall" /></Link>

            <Link to="/messages">
              <button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Messages</button>
            </Link><br />

            <Link to="/account">
              <button onMouseUp={this.props.navClose} onTouchEnd={this.props.navClose} className="navSmallButton">Account</button>
            </Link><br />

            <Link to="/"><button onMouseUp={this.logOut} onTouchEnd={this.logOut} className="navSmallButton">Logout</button></Link><br />

          </div>

        </div>

      )
    }



  }
}

export default MobileNavBar;
